export enum ProductName {
  cancelFlow = 'cancelFlow',
  reactivation = 'reactivation',
  dunning = 'dunning',
}

export const productNameToPath = (productName: ProductName) => {
  switch (productName) {
    case ProductName.cancelFlow:
      return '/cancellation';
    case ProductName.reactivation:
      return '/reactivation';
    case ProductName.dunning:
      return '/dunning';
  }
};

export const productNameToTitle = (productName: ProductName) => {
  switch (productName) {
    case ProductName.cancelFlow:
      return 'Cancel Flows';
    case ProductName.reactivation:
      return 'Reactivations';
    case ProductName.dunning:
      return 'Payment Recovery';
  }
};
