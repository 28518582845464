import { IState } from '@/store/types';
import { getProductByName } from '@/helpers/products';
import { IStripeSubscription, IStripeSubscriptionItem } from '@/store/stripe-types';

export const hasProductSubscription = (state: IState, productName: string) => {
  const product = getProductByName(state, productName);
  if (!product) {
    return false;
  }
  return state.org?.stripe?.subscriptions?.data?.some((dataItem) =>
    dataItem.items.data.some((item) => item.plan.product === product.stripeProductId)
  );
};

export const hasNonTrialProductSubscription = (state: IState, productName: string) => {
  const product = getProductByName(state, productName);

  if (!product) {
    return false;
  }
  return (
    state.org?.stripe?.subscriptions?.data?.some((dataItem) => dataItem.items.data.some((item) => item.plan.product === product.stripeProductId)) &&
    product.isTrial !== true
  );
};

export const hasTrialSubscription = (state: IState, productName: string) => {
  const product = getProductByName(state, productName);
  if (!product) {
    return false;
  }
  return state.org?.stripeCustomer?.subscribedProducts?.some((dataItem) => dataItem.trialPrice?.id === product.trialPrice.id);
};

export const hasNonMonthlySubscription = (state: IState) => {
  const subscriptions = state.org?.stripe?.subscriptions?.data;
  if (!subscriptions) {
    return false;
  }
  return subscriptions.some((subscription: IStripeSubscription) => {
    return subscription.items.data.some((item: IStripeSubscriptionItem) => {
      const isNotMonthly = item.plan.interval !== 'month';
      const isNotSingleInterval = item.plan.interval_count !== 1;
      return isNotMonthly || isNotSingleInterval;
    });
  });
};
