<template>
  <div class="w-56 pl-6 py-4 bg-brand-light-sand flex flex-col items-start" :key="$route.fullPath">
    <div class="flex-shrink-0 flex items-center my-2">
      <a href="/">
        <img class="h-7 w-auto ml-2 mt-1" src="@/assets/brand/ck-logo.svg" alt="Churnkey Logo" />
      </a>
    </div>
    <div class="flex-1 mt-6 w-full space-y-5 overflow-y-auto flex flex-col">
      <div v-for="group in authorizedNavGroups" :key="group.key" class="space-y-1 flex-shrink-0 first:flex-grow">
        <div v-if="group.name" class="text-brand-mid-brown uppercase tracking-widest text-xs font-extrabold ml-3">{{ group.name }}</div>
        <router-link
          v-for="route in group.routes"
          :key="route.path"
          :to="route.path"
          :exact="route.path === '/'"
          class="group w-full px-3 py-2 rounded-md flex flex-col items-start text-sm font-bold transition-colors text-brand-brown hover:bg-brand-dark-sand hover:bg-opacity-70 hover:text-brand-brown"
        >
          <div class="flex items-center space-x-2 capitalize">
            <component :is="route.meta.icon" v-if="route.meta.icon" />
            <span>{{ routeLabel(route) }}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarDesktopContent',
  computed: {
    ...mapGetters(['authorizedNavGroups', 'routeLabel']),
  },
};
</script>

<style scoped lang="scss">
.router-link-active {
  @apply bg-brand-medium-blue hover:bg-brand-medium-blue;
  @apply text-brand-blue hover:text-brand-blue;
  @apply hover:bg-opacity-100;
}
</style>
