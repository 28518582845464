import { OfferType } from '@/models/Offer';
import { STEP_TYPE } from '@/store/shared';
import { IBlueprint } from '@/store/types';

const getOfferCoupon = (offer: any) => {
  if (offer.offerType === OfferType.DISCOUNT) {
    return offer.discountConfig.couponId;
    // For couponless providers like Paddle:
    // If we need more flexibility in the future,
    // we could return additional discountConfig data from here,
    // instead of encoding discount settings into couponIds as we do now.
    // Then the org.controller would relay this data into the provider handler.
  }
  return null;
};

export const findBlueprintCouponIds = (blueprint: IBlueprint) => {
  const couponSet = blueprint.steps.reduce((coupons, step) => {
    if (step.stepType === STEP_TYPE.OFFER) {
      const couponId = getOfferCoupon(step);
      if (couponId) {
        coupons.add(couponId);
      }
    } else if (step.stepType === STEP_TYPE.SURVEY) {
      step.survey.choices.forEach((choice: any) => {
        if (choice.offer) {
          const couponId = getOfferCoupon(choice.offer);
          if (couponId) {
            coupons.add(couponId);
          }
        }
      });
    }
    return coupons;
  }, new Set());
  return Array.from(couponSet);
};

// mock paddle coupon creation
export function parseCustomCoupon(couponId: string) {
  const [customAmount, customDuration] = couponId.split('@');
  return {
    customAmount: parseInt(customAmount, 10),
    customDuration,
  };
}

function mapCouponDuration(durationType: string) {
  const mapping = {
    ONCE: 'once',
    FOREVER: 'forever',
  };
  return mapping[durationType];
}

export function parsePaddleCoupon(couponId: string) {
  const { customDuration, customAmount } = parseCustomCoupon(couponId);
  return {
    id: couponId,
    name: null,
    duration: mapCouponDuration(customDuration),
    duration_in_months: customDuration === 'ONCE' ? 1 : null,
    amount_off: customAmount,
    percent_off: null,
    redeem_by: null,
  };
}

export function mockPaddleCoupons(couponIds: string[]) {
  const result = couponIds.map(parsePaddleCoupon);
  return result;
}
