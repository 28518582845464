import Vue from 'vue';
import { ICustomDomain } from '@/store/types';

import { parseDomain, ParseResultType } from 'parse-domain';

export const removeDomain = (url: string, referenceUrl: string) => {
  if (!url) return '';
  try {
    const result = parseDomain(referenceUrl || url);
    let domain = '';
    if (result.type === ParseResultType.Listed) {
      domain = `.${result.domain}.${result.topLevelDomains.join('.')}`;
    }
    return url.replace(domain, '');
  } catch (err) {
    console.log('Error parsing domain', url, referenceUrl, err);
    return url;
  }
};

export const extractDomain = (url: string) => {
  if (!url) return '';
  try {
    const result = parseDomain(url);
    let domain = '';
    if (result.type === ParseResultType.Listed) {
      domain = `${result.domain}.${result.topLevelDomains.join('.')}`;
    }
    return domain;
  } catch (err) {
    console.log('Error parsing domain', url, err);
    return url;
  }
};

export const customDomainIsValid = (domain: ICustomDomain) => {
  return domain && domain.isResolving && domain.hasSSL && domain.status !== 'DNS_INCORRECT';
};

export const customDomainStatus = (domain: ICustomDomain) => {
  if (!domain) {
    return {
      status: 'Error',
      info: 'We were unable to load your domain configuration. Please contact support.',
    };
  }

  let status = 'Pending';
  let info = '';

  if (!domain.isResolving) {
    status = 'Verifying';
    info = '';
    // info = 'We are verifying your domain configuration. This may take a few minutes.';
  }

  if (!domain.hasSSL) {
    status = 'Verifying';
    info = '';
    // info = 'We are creating your SSL certificates. This may take a few minutes.';
  }

  if (domain.status === 'DNS_INCORRECT') {
    status = 'Error';
    info = "Please make sure that you have added the correct DNS record, and that no other 'A' records exist for this domain.";
  }

  if (domain.status === 'SSL_ERROR') {
    status = 'Error';
    info = 'We were unable to create your SSL certificates. Please contact support.';
  }

  if (customDomainIsValid(domain)) {
    status = 'Verified';
    info = '';
  }

  return {
    status,
    statusInformation: info,
  };
};

Vue.filter('removeDomain', removeDomain);
Vue.filter('extractDomain', extractDomain);
