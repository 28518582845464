import Vue from 'vue';

Vue.directive('can-show', {
  inserted(el, binding, vnode) {
    // Split the string into section and action based on the colon
    const [section, action] = binding.value.split(':');

    const hasPermission = vnode.context.$store.getters.hasPermission(`${section}:${action}`);
    if (!hasPermission) {
      // Hide the element from the DOM if the user does not have the required permission
      el.style.display = 'none';
    }
  },
});

Vue.directive('can-enable', {
  bind(el, binding, vnode) {
    // Split the string into section and action
    const [section, action] = binding.value.split(':');

    const hasPermission = vnode.context.$store.getters.hasPermission(`${section}:${action}`);

    if (!hasPermission) {
      // Disable the element

      el.disabled = true;
      el.style.pointerEvents = 'none';
      el.style.opacity = '0.6';
    }
  },
});

Vue.directive('can-alt-text', {
  inserted(el, binding, vnode) {
    const [section, action, text] = binding.value.split(':');

    const hasPermission = vnode.context.$store.getters.hasPermission(`${section}:${action}`);
    if (!hasPermission) {
      // Look for a child element with the data-can-alt-text-target attribute
      const target = el.querySelector('[data-can-alt-text-target="true"]');
      if (target) {
        // Replace the target element's inner text

        target.innerText = text || 'Permission Denied';
      } else {
        // Fallback: Replace the element's inner text
        el.innerText = text || 'Permission Denied';
      }
    }
  },
});
