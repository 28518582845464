// @ts-nocheck
import { IAIJob } from '@/store/types';
import Axios from 'axios';

const RESOURCE = '/api/ai';

export default {
  /**
   * @param {String} load all active jobs for this org
   */
  loadJobs(): { jobs: IAIJob[] } {
    return Axios.get(`${RESOURCE}/jobs`);
  },
  generate(options: any): IAIJob {
    return Axios.post(`${RESOURCE}/generate`, options);
  },
  rework(options: any): IAIJob {
    return Axios.put(`${RESOURCE}/rework`, options);
  },
  complete(id: string): IAIJob {
    return Axios.put(`${RESOURCE}/job/${id}/complete`);
  },
  cancel(id: string): IAIJob {
    return Axios.delete(`${RESOURCE}/job/${id}`);
  },
};
