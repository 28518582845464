export const signedUp = {
  event: 'trackSignUp',
  action: 'signedUp',
  category: 'Revenue',
  label: 'New User',
  nonInteraction: true,
};

export const connectedStripe = {
  event: 'trackStripeConnect',
  action: 'connectedStripe',
  category: 'Revenue',
  label: 'Connected Stripe',
  nonInteraction: true,
};

export const subscribed = {
  event: 'trackSubscription',
  action: 'subscribed',
  category: 'Revenue',
  label: 'New Customer',
  nonInteraction: true,
};

export const createdBlueprint = {
  event: 'trackBlueprintInit',
  action: 'createdBlueprint',
  category: 'Revenue',
  label: 'Created Blueprint',
  nonInteraction: true,
};

export const scheduledDemo = {
  event: 'demoScheduled',
  action: 'scheduledDemo',
  category: 'Revenue',
  label: 'Scheduled Demo',
  nonInteraction: true,
};

export const startedChat = {
  event: 'startedChat',
  action: 'startedChat',
  category: 'Revenue',
  label: 'Started Chat',
  nonInteraction: true,
};
