import { pluralizeWithCount } from '@/helpers';
import { CustomDiscountDuration, PauseInterval } from '@/store/types';

export enum OfferType {
  PAUSE = 'PAUSE',
  DISCOUNT = 'DISCOUNT',
  CONTACT = 'CONTACT',
  PLAN_CHANGE = 'PLAN_CHANGE',
  REDIRECT = 'REDIRECT',
  TRIAL_EXTENSION = 'TRIAL_EXTENSION',
}

export interface IOfferBase {
  guid: string;
  offerType: OfferType;
  header?: string;
  description?: string;
}

// PAUSE

export interface IOfferPauseConfig {
  maxPauseLength: number;
  pauseInterval: PauseInterval;
  datePicker?: boolean;
}

export interface IOfferPause extends IOfferBase {
  offerType: OfferType.PAUSE;
  pauseConfig: IOfferPauseConfig;
}

export interface OfferPauseDuration {
  amount: number;
  unit: PauseInterval;
  label: string;
}

export function toPauseDuration(amount: number, unit: PauseInterval): OfferPauseDuration {
  return {
    amount,
    unit,
    label: pluralizeWithCount(amount, unit).toLocaleLowerCase(),
  };
}

export const OFFER_PAUSE_DURATIONS: OfferPauseDuration[] = [
  // ...Array.from({ length: 2 }, (_, i) => toPauseDuration(i + 1, 'WEEK')),
  ...Array.from({ length: 12 }, (_, i) => toPauseDuration(i + 1, 'MONTH')),
];

// PLAN_CHANGE

export interface IOfferPlanChangeConfig {
  options: string[];
}

export interface IOfferPlanChange extends IOfferBase {
  planChangeConfig: IOfferPlanChangeConfig;
  offerType: OfferType.PLAN_CHANGE;
}

// CONTACT
export interface IOfferContactConfig {}
export interface IOfferContact extends IOfferBase {
  contactConfig: IOfferContactConfig;
  offerType: OfferType.CONTACT;
}

// DISCOUNT

export interface IOfferDiscountConfig {
  couponId: string;
  customAmount?: number;
  customDuration?: CustomDiscountDuration;
}

export interface IOfferDiscount extends IOfferBase {
  discountConfig: IOfferDiscountConfig;
  offerType: OfferType.DISCOUNT;
}

// REDIRECT

export interface IOfferRedirectConfig {
  redirectUrl: string;
  redirectLabel: string;
}
export interface IOfferRedirect extends IOfferBase {
  redirectConfig: IOfferRedirectConfig;
  offerType: OfferType.REDIRECT;
}

// TRIAL_EXTENSION

export interface IOfferTrialExtensionConfig {
  trialExtensionDays: number;
}

export interface IOfferTrialExtension extends IOfferBase {
  trialExtensionConfig: IOfferTrialExtensionConfig;
  offerType: OfferType.TRIAL_EXTENSION;
}

export type Offer = IOfferPause | IOfferContact | IOfferRedirect | IOfferDiscount | IOfferPlanChange | IOfferTrialExtension;
