import ReactivationService from '@/api/reactivation.service';
import { timeDay, ascending, descending } from 'd3';
import { generateReactivationSequence } from '@/helpers/templates';
import OrgService from '@/api/org.service';
import StatsService from '@/api/stats.service';
import eventBus from '@/helpers/event-bus';
import { LOAD_STATE, SAVE_STATE, CAMPAIGN_SCHEDULE, REACTIVATION_DEFAULT_SEND_TIME } from '../shared';
import * as MUTATE from '../mutation-types';
import * as ACT from '../action-types';
import {
  EmailMergeField,
  CampaignSchedule,
  CampaignBlueprintState,
  ICampaignBlueprint,
  IEmailBlueprint,
  IEmailCount,
  IEmailStatusCount,
  IEmailsByDate,
  IExclusion,
  IState,
  IUser,
  LoadState,
} from '../types';
import { generateFetch } from './dunning-dashboard';
import { ProductName } from '@/constants/ProductName';
import { hasNonTrialProductSubscription, hasProductSubscription, hasTrialSubscription } from '@/helpers/subscriptions';
import { getProductByName } from '@/helpers/products';

interface IActionContext {
  commit: any;
  dispatch: any;
  state: IState;
  getters: any;
}

const storeCampaignData = (state: IState, { id, data }: { id: string; data: Partial<ICampaignBlueprint> }) => {
  const campaignData = {
    ...state.reactivationCampaignBlueprints[id],
    ...data,
  };
  state.reactivationCampaignBlueprints = {
    ...state.reactivationCampaignBlueprints,
    [id]: campaignData,
  };
};

const storeExclusionData = (state: IState, { id, data }: { id: string; data: Partial<IExclusion> }) => {
  const exclusionData = {
    ...state.reactivationExclusions[id],
    ...data,
  };
  state.reactivationExclusions = {
    ...state.reactivationExclusions,
    [id]: exclusionData,
  };
};

const getEmailSendDate = (email: IEmailBlueprint): Date => {
  if (!email.sendOnDate) {
    throw new Error('sendOnDate is required');
  }

  const sendOnDate = new Date(email.sendOnDate);
  const timeToSend = email.timeToSend || REACTIVATION_DEFAULT_SEND_TIME;
  let [hours, minutes] = timeToSend.split(':').map(Number);

  if (isNaN(hours) || isNaN(minutes)) {
    hours = 9;
    minutes = 30;
  }

  sendOnDate.setHours(hours, minutes);
  return sendOnDate;
};

const sortedEmails = (blueprint: ICampaignBlueprint) => {
  if (blueprint.campaignSchedule === CAMPAIGN_SCHEDULE.ONE_TIME) {
    const emails = blueprint.emails.filter((email: IEmailBlueprint) => !email.delete);
    if (!emails?.length) {
      return [];
    }
    return [...emails].sort((a, b) => {
      const dateA = getEmailSendDate(a);
      const dateB = getEmailSendDate(b);
      return dateA.getTime() - dateB.getTime();
    });
  }
  return blueprint.emails || [];
};

const computeBlueprintState = (blueprint: ICampaignBlueprint) => {
  if (!blueprint || blueprint.loadState !== LOAD_STATE.LOADED) {
    return null;
  }
  if (!blueprint.enabled) {
    return blueprint.campaignSchedule === CAMPAIGN_SCHEDULE.ONE_TIME ? CampaignBlueprintState.DRAFT : CampaignBlueprintState.DISABLED;
  }
  if (!blueprint.publishedAt) {
    return blueprint.campaignSchedule === CAMPAIGN_SCHEDULE.ONE_TIME ? CampaignBlueprintState.DRAFT : CampaignBlueprintState.SETUP;
  }

  // one-time campaign statuses
  if (blueprint.campaignSchedule === CAMPAIGN_SCHEDULE.ONE_TIME) {
    const deliveryStartDate = blueprint.deliveryStart ? new Date(blueprint.deliveryStart) : undefined;
    const deliveryEndDate = blueprint.deliveryEnd ? new Date(blueprint.deliveryEnd) : undefined;
    const firstEmailDate = getEmailSendDate(sortedEmails(blueprint)[0]);
    const lastEmailDate = getEmailSendDate(sortedEmails(blueprint)[sortedEmails(blueprint).length - 1]);
    const startDate = deliveryStartDate || firstEmailDate;
    const endDate = deliveryEndDate || lastEmailDate;
    if (!(blueprint.emails || []).length) {
      return CampaignBlueprintState.DRAFT;
    }
    if (+new Date(endDate) < +new Date()) {
      return CampaignBlueprintState.SENT;
    }
    if (+new Date(startDate) < +new Date()) {
      return CampaignBlueprintState.SENDING;
    }
    return CampaignBlueprintState.SCHEDULED;
  }

  // fallback status for recurring campaigns
  return CampaignBlueprintState.ACTIVE;
};

const reactivationModule = {
  // reactivation object (with dkim etc) lives under org
  state: {
    currentReactivationCampaignBlueprintId: null,
    currentReactivationEmailBlueprintId: null,
    // a count of survey responses in the last 60 days
    recentSurveyResponses: [] as { label: string; count: number }[],
    recentSurveyResponsesLoadState: LOAD_STATE.UNLOADED,
    reactivationCampaignCounts: {},
    reactivationCampaignCountsLoadState: LOAD_STATE.UNLOADED,
    reactivationBlockCounts: {},
    reactivationBlockCountsLoadState: LOAD_STATE.UNLOADED,
    reactivationEmailOpenCounts: {} as Record<string, IEmailStatusCount>,
    reactivationEmailOpenCountsLoadState: LOAD_STATE.UNLOADED,
    reactivationEmailTimeline: [],
    reactivationEmailTimelineLoadState: LOAD_STATE.UNLOADED,
  },

  mutations: {
    [MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA](state: IState, { id, data }: { id: string; data: any }) {
      const campaignBlueprintData = {
        ...state.reactivationCampaignBlueprints[id],
        ...data,
      };
      const computedState = computeBlueprintState(state.reactivationCampaignBlueprints[id]);
      if (computedState) {
        campaignBlueprintData.state = computedState;
      }
      state.reactivationCampaignBlueprints = {
        ...state.reactivationCampaignBlueprints,
        [id]: campaignBlueprintData,
      };
    },
    [MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_EMAIL_COUNT](state: IState, { id, data }: { id: string; data: IEmailCount }) {
      const emailCount = {
        ...state.reactivationCampaignBlueprintEmailCounts[id],
        ...data,
      };
      state.reactivationCampaignBlueprintEmailCounts = {
        ...state.reactivationCampaignBlueprintEmailCounts,
        [id]: emailCount,
      };
    },
    [MUTATE.SET_CURRENT_REACTIVATION_CAMPAIGN_BLUEPRINT_ID](state: IState, blueprintId: string) {
      state.currentReactivationCampaignBlueprintId = blueprintId;
    },
    [MUTATE.SET_CURRENT_REACTIVATION_EMAIL_BLUEPRINT_ID](state: IState, blueprintId: string) {
      state.currentReactivationEmailBlueprintId = blueprintId;
    },
    [MUTATE.STORE_REACTIVATION_SETTINGS](state: IState, payload: any) {
      if (state.org?.reactivation) {
        state.org.reactivation = { ...state.org.reactivation, ...payload };
      }
    },
    // does not override entire email
    [MUTATE.STORE_REACTIVATION_EMAIL_DATA](
      state: IState,
      { campaignId, emailId, data }: { campaignId: string; emailId: string; data: Partial<IEmailBlueprint> }
    ) {
      const campaign = state.reactivationCampaignBlueprints[campaignId];
      if (campaign) {
        const email = campaign.emails.find((e) => e.guid === emailId);
        if (email) {
          const newEmail = {
            ...email,
            ...data,
          };
          const newEmails = campaign.emails.map((e) => {
            if (e.guid === emailId) {
              return newEmail;
            }
            return e;
          });
          storeCampaignData(state, { id: campaignId, data: { emails: newEmails } });
        }
      }
    },
    // overrides entire email
    [MUTATE.SET_REACTIVATION_CAMPAIGN_EMAIL](
      state: IState,
      { campaignId, emailId, data }: { campaignId: string; emailId: string; data: IEmailBlueprint }
    ) {
      const campaign = state.reactivationCampaignBlueprints[campaignId];
      if (campaign) {
        const email = campaign.emails.find((e) => e.guid === emailId);
        if (email) {
          const newEmail = data;
          // store vs set
          // const newEmail = {
          //   ...email,
          //   ...data,
          // };
          const newEmails = campaign.emails.map((e) => {
            if (e.guid === emailId) {
              return newEmail;
            }
            return e;
          });
          storeCampaignData(state, { id: campaignId, data: { emails: newEmails, saveState: SAVE_STATE.UNSAVED } });
        }
      }
    },
    [MUTATE.STORE_REACTIVATION_EXCLUSION_DATA](state: IState, { id, data }: { id: string; data: any }) {
      const reactivationExclusionData = {
        ...state.reactivationExclusions[id],
        ...data,
      };
      state.reactivationExclusions = {
        ...state.reactivationExclusions,
        [id]: reactivationExclusionData,
      };
    },
    [MUTATE.SET_RECENT_SURVEY_RESPONSES](state: IState, responses: { label: string; count: number }[]) {
      state.recentSurveyResponses = responses;
    },
    [MUTATE.SET_RECENT_SURVEY_RESPONSES_LOAD_STATE](state: IState, loadState: LoadState) {
      state.recentSurveyResponsesLoadState = loadState;
    },
    [MUTATE.SET_REACTIVATION_CAMPAIGNS_COUNT](state: IState, counts: Record<string, number>) {
      state.reactivationCampaignCounts = counts;
    },
    [MUTATE.SET_REACTIVATION_CAMPAIGNS_COUNT_LOAD_STATE](state: IState, loadState: LoadState) {
      state.reactivationCampaignCountsLoadState = loadState;
    },
    [MUTATE.SET_REACTIVATION_BLOCK_COUNT](state: IState, counts: Record<string, number>) {
      state.reactivationBlockCounts = counts;
    },
    [MUTATE.SET_REACTIVATION_BLOCK_COUNT_LOAD_STATE](state: IState, loadState: LoadState) {
      state.reactivationBlockCountsLoadState = loadState;
    },
    [MUTATE.SET_REACTIVATION_EMAIL_OPEN_COUNT](state: IState, counts: Record<string, IEmailStatusCount>) {
      state.reactivationEmailOpenCounts = counts;
    },
    [MUTATE.SET_REACTIVATION_EMAIL_OPEN_COUNT_LOAD_STATE](state: IState, loadState: LoadState) {
      state.reactivationEmailOpenCountsLoadState = loadState;
    },
    [MUTATE.SET_REACTIVATION_EMAIL_TIMELINE](state: IState, stats: IEmailsByDate[]) {
      state.reactivationEmailTimeline = stats;
    },
    [MUTATE.SET_REACTIVATION_EMAIL_TIMELINE_LOAD_STATE](state: IState, loadState: LoadState) {
      state.reactivationEmailTimelineLoadState = loadState;
    },
  },

  actions: {
    [ACT.FETCH_REACTIVATION_EMAIL_TIMELINE]: generateFetch({
      getLoadState: (state: IState) => state.reactivationEmailTimelineLoadState,
      setLoadStateMutation: MUTATE.SET_REACTIVATION_EMAIL_TIMELINE_LOAD_STATE,
      fetchValue: StatsService.reactivationEmailTimeline,
      setValueMutation: MUTATE.SET_REACTIVATION_EMAIL_TIMELINE,
    }),
    async [ACT.FETCH_RECENT_SURVEY_RESPONSES]({ commit, state }: IActionContext) {
      if ([LOAD_STATE.LOADING, LOAD_STATE.LOADED].includes(state.recentSurveyResponsesLoadState)) return;

      const responses = await StatsService.surveyResponses({
        filter: {
          startDate: timeDay.floor(timeDay.offset(new Date(), -60)),
        },
      });
      responses.sort((a, b) => descending(a.count, b.count));
      // aggregate across responses, not case sensitive, but store label as cased version of response
      const aggregated = responses.reduce(
        (acc: Record<string, { label: string; count: number }>, item: { response: string; count: number }) => {
          const lowercase = item.response.toLowerCase();
          if (!acc[lowercase]) {
            acc[lowercase] = { label: item.response, count: 0 };
          }
          acc[lowercase].count += item.count;
          return acc;
        },
        {} as Record<string, { label: string; count: number }>
      );
      commit(MUTATE.SET_RECENT_SURVEY_RESPONSES, Object.values(aggregated));
      commit(MUTATE.SET_RECENT_SURVEY_RESPONSES_LOAD_STATE, LOAD_STATE.LOADED);
    },
    // sync reactivation info from org and campaign blueprints
    async [ACT.SYNC_REACTIVATION]({ state, commit, dispatch }: IActionContext, options: any) {
      await dispatch(ACT.SYNC_ORG);
      await Promise.all([dispatch(ACT.SYNC_REACTIVATION_CAMPAIGN_BLUEPRINTS), dispatch(ACT.SYNC_REACTIVATION_EXCLUSIONS)]);
    },
    async [ACT.FETCH_REACTIVATION_CAMPAIGN_COUNTS]({ state, commit, dispatch }: IActionContext, options: any) {
      if (state.reactivationCampaignCountsLoadState === LOAD_STATE.LOADING) return;
      commit(MUTATE.SET_REACTIVATION_CAMPAIGNS_COUNT_LOAD_STATE, LOAD_STATE.LOADING);
      const activeCampaignCounts = await StatsService.reactivationCampaignCountLive({
        filter: {
          startDate: timeDay.floor(timeDay.offset(new Date(), -60)),
          active: true,
        },
        breakdown: ['campaignBlueprintId'],
      });
      const campaignCountMap = activeCampaignCounts.reduce(
        (acc, { campaignBlueprintId, count }) => {
          acc[campaignBlueprintId] = count;
          return acc;
        },
        {} as Record<string, number>
      );
      commit(MUTATE.SET_REACTIVATION_CAMPAIGNS_COUNT, campaignCountMap);
      commit(MUTATE.SET_REACTIVATION_CAMPAIGNS_COUNT_LOAD_STATE, LOAD_STATE.LOADED);
    },
    async [ACT.FETCH_REACTIVATION_BLOCK_COUNTS]({ state, commit, dispatch }: IActionContext, options: any) {
      if (state.reactivationBlockCountsLoadState === LOAD_STATE.LOADING) return;
      commit(MUTATE.SET_REACTIVATION_BLOCK_COUNT_LOAD_STATE, LOAD_STATE.LOADING);
      const activeBlockCounts = await StatsService.reactivationBlockCounts({
        filter: {
          startDate: timeDay.floor(timeDay.offset(new Date(), -60)),
        },
      });
      const campaignCountMap = activeBlockCounts.reduce(
        (acc, { exclusionId, count }) => {
          acc[exclusionId] = count;
          return acc;
        },
        {} as Record<string, number>
      );
      commit(MUTATE.SET_REACTIVATION_BLOCK_COUNT, campaignCountMap);
      commit(MUTATE.SET_REACTIVATION_BLOCK_COUNT_LOAD_STATE, LOAD_STATE.LOADED);
    },
    async [ACT.FETCH_REACTIVATION_EMAIL_OPEN_COUNTS]({ state, commit, dispatch }: IActionContext, options: any) {
      if (state.reactivationEmailOpenCountsLoadState === LOAD_STATE.LOADING) return;
      commit(MUTATE.SET_REACTIVATION_EMAIL_OPEN_COUNT_LOAD_STATE, LOAD_STATE.LOADING);
      const activeEmailOpenCounts = await StatsService.reactivationCampaignEmailCount({
        filter: {
          startDate: timeDay.floor(timeDay.offset(new Date(), -120)),
        },
      });

      const campaignCountMap = activeEmailOpenCounts.reduce(
        (acc, { campaignBlueprintId, campaignEmailStatus, count }) => {
          if (!acc[campaignBlueprintId]) acc[campaignBlueprintId] = {};

          acc[campaignBlueprintId][campaignEmailStatus] = count;
          return acc;
        },
        {} as Record<string, Record<string, number>>
      );

      const uniqueCampaignIds = [...new Set(activeEmailOpenCounts.map((e) => e.campaignBlueprintId))] as string[];

      const emailStatusMap = uniqueCampaignIds.reduce(
        (acc, id) => {
          const statusCounts = campaignCountMap[id];
          const sent = statusCounts?.sent || 0;
          const delivered = statusCounts?.delivered || 0;
          const clicked = statusCounts?.clicked || 0;
          const opened = statusCounts?.opened || 0;
          const scheduled = statusCounts?.scheduled || 0;
          const bounced = statusCounts?.bounced || 0;
          const recovered = statusCounts?.recovered || 0;

          acc[id] = {
            sent: sent + clicked + opened + delivered,
            opened: opened + clicked,
            clicked: clicked,
            bounced,
            scheduled,
            recovered,
          };

          return acc;
        },
        {} as Record<string, IEmailStatusCount>
      );

      commit(MUTATE.SET_REACTIVATION_EMAIL_OPEN_COUNT, emailStatusMap);
      commit(MUTATE.SET_REACTIVATION_EMAIL_OPEN_COUNT_LOAD_STATE, LOAD_STATE.LOADED);
    },
    async [ACT.SYNC_REACTIVATION_CAMPAIGN_BLUEPRINTS]({ state, commit, dispatch }: IActionContext, options: any) {
      try {
        // fetch campaign blueprints and email counts
        state.org?.reactivation?.campaignBlueprints?.forEach((id) => {
          commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.LOADING } });
        });
        dispatch(ACT.FETCH_REACTIVATION_CAMPAIGN_COUNTS);
        const [campaignBlueprints] = await Promise.all([ReactivationService.getAllCampaignBlueprints()]);
        campaignBlueprints?.forEach((campaignBlueprint) => {
          commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, {
            id: campaignBlueprint._id,
            data: {
              ...campaignBlueprint,
              loadState: LOAD_STATE.LOADED,
              saveState: SAVE_STATE.SAVED,
            },
          });
        });
      } catch (e) {
        console.log('Error syncing campaign blueprints', e);
        state.org?.reactivation?.campaignBlueprints?.forEach((id) => {
          commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
          commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_EMAIL_COUNT, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
        });
      }
    },

    async [ACT.SYNC_REACTIVATION_EXCLUSIONS]({ state, commit, dispatch }: IActionContext, options: any) {
      try {
        // fetch reactivation exclusions and block counts
        state.org?.reactivation?.exclusions?.forEach((id) => {
          commit(MUTATE.STORE_REACTIVATION_EXCLUSION_DATA, { id, data: { loadState: LOAD_STATE.LOADING } });
        });
        dispatch(ACT.FETCH_REACTIVATION_BLOCK_COUNTS);
        const exclusions = await ReactivationService.getAllExclusions();
        exclusions?.forEach((exclusion) => {
          commit(MUTATE.STORE_REACTIVATION_EXCLUSION_DATA, {
            id: exclusion._id,
            data: {
              ...exclusion,
              loadState: LOAD_STATE.LOADED,
              saveState: SAVE_STATE.SAVED,
            },
          });
        });
      } catch (e) {
        console.log('Error syncing reactivation exclusions', e);
        state.org?.reactivation?.exclusions?.forEach((id) => {
          commit(MUTATE.STORE_REACTIVATION_EXCLUSION_DATA, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
          // commit(MUTATE.STORE_REACTIVATION_EXCLUSION_BLOCK_COUNT, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
        });
      }
    },
    async [ACT.FETCH_REACTIVATION_CAMPAIGN_BLUEPRINT]({ commit, state }: IActionContext, id: string) {
      commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.LOADING } });
      try {
        const raw = await ReactivationService.getCampaignBlueprint(id);
        commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: raw });
        commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.LOADED, saveState: SAVE_STATE.SAVED } });
      } catch (e) {
        console.log('Error fetching campaign blueprint', e);
        commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
      }
    },
    async [ACT.CREATE_REACTIVATION_CAMPAIGN_BLUEPRINT](
      { commit, state }: IActionContext,
      options: { segmented: boolean; campaignSchedule: CampaignSchedule }
    ) {
      try {
        // generate a template
        const team = await OrgService.getMembers(state.user?.org as string);
        const admin = team.find((user) => user.role === 'ORG_OWNER');
        const user = admin || (state.user as IUser);
        const campaign = generateReactivationSequence({
          from: user.email,
          replyTo: user.email,
          company: state.org?.name || '',
          senderName: user.name || '',
          ...options,
        });
        const campaignBlueprint = await ReactivationService.createCampaignBlueprint(campaign);
        return campaignBlueprint;
      } catch (e) {
        console.log('Error create campaign blueprint', e);
        return null;
      }
    },
    // persist changes to DB
    async [ACT.UPDATE_REACTIVATION_CAMPAIGN_BLUEPRINT]({ commit, state }: IActionContext, { id }: { id: string }) {
      try {
        // emails that haven't been deleted
        const emailsToKeep = state.reactivationCampaignBlueprints[id].emails.filter((e) => !e.delete);
        commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.SAVING, emails: emailsToKeep } });

        const updated = await ReactivationService.updateCampaignBlueprint(id, {
          data: state.reactivationCampaignBlueprints[id],
        });
        const { newBlueprint } = updated;
        // change to saved if it hasn't been marked as unsaved while saving
        if (state.reactivationCampaignBlueprints[id].saveState === SAVE_STATE.SAVING) {
          commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, {
            id,
            data: { saveState: SAVE_STATE.SAVED, updatedAt: newBlueprint.updatedAt },
          });
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const email of state.reactivationCampaignBlueprints[id].emails) {
          const emailId = email.guid;
          const data: Partial<ICampaignBlueprint> = { saveState: SAVE_STATE.SAVED };
          // Store _id of any newly created emails
          if (!email._id) {
            const newEmail = newBlueprint.emails.find((e) => e.guid === emailId);
            data._id = newEmail?._id;
          }
          commit(MUTATE.STORE_REACTIVATION_EMAIL_DATA, { campaignId: id, emailId, data });
        }
        return updated;
      } catch (e) {
        console.log(e);
        commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
        eventBus.$emit('alert', 'Error saving campaign', e?.response?.data || e.message);
        return null;
      }
    },

    async [ACT.CLONE_REACTIVATION_CAMPAIGN_BLUEPRINT](
      { state, commit, dispatch }: IActionContext,
      { id, pushDates = false }: { id: string; pushDates: boolean }
    ) {
      try {
        const campaignBlueprint = await ReactivationService.cloneCampaignBlueprint(id, pushDates);
        await dispatch(ACT.SYNC_REACTIVATION);
        return campaignBlueprint;
      } catch (error) {
        console.log('Error cloning campaign blueprint', error);
        return null;
      }
    },

    async [ACT.SEND_REACTIVATION_TEST_EMAIL](
      { state, commit, dispatch }: IActionContext,
      { campaignBlueprintId, emailBlueprintId, emailTo }: { campaignBlueprintId: string; emailBlueprintId: number; emailTo: string }
    ) {
      try {
        return await ReactivationService.sendTestEmail(campaignBlueprintId, emailBlueprintId, emailTo);
      } catch (error) {
        return { error: error.message || 'Unknown error!' };
      }
    },

    async [ACT.SCHEDULE_SAVE_REACTIVATION_CAMPAIGN_BLUEPRINT](
      { commit, dispatch, state, getters }: IActionContext,
      { id, delay }: { id: string; delay?: number }
    ) {
      const batchUpdateDelay = delay !== undefined ? delay : 2000;
      commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.UNSAVED } });
      if (!getters.hasPermission('reactivation:write')) return;
      const existingBatchUpdateTimeout = state.reactivationCampaignBlueprints[id].batchUpdateTimeout;
      if (existingBatchUpdateTimeout) {
        clearTimeout(existingBatchUpdateTimeout);
      }
      const batchUpdate = () => {
        dispatch(ACT.UPDATE_REACTIVATION_CAMPAIGN_BLUEPRINT, { id });
      };
      const batchUpdateTimeout = setTimeout(batchUpdate, batchUpdateDelay);
      commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: { batchUpdateTimeout } });
    },

    async [ACT.UPDATE_REACTIVATION_CAMPAIGN_BLUEPRINTS_ORDER](
      { state, getters }: IActionContext,
      { campaignBlueprints }: { campaignBlueprints: string[] }
    ) {
      try {
        if (!getters.hasPermission('reactivation:write')) return;
        const orgId = getters.org._id;
        const response = await OrgService.reorderReactivationCampaignBlueprints(orgId, { campaignBlueprints });
        if (response.taskId) {
          eventBus.$emit('pollTask', response.taskId);
        }
        return true;
      } catch (error) {
        console.log('Error reordering campaign blueprints', error);
        return null;
      }
    },

    async [ACT.PUBLISH_REACTIVATION_CAMPAIGN_BLUEPRINT]({ state, dispatch, commit }: IActionContext, { id }: { id: string }) {
      try {
        // freeze operations during publish
        const campaignBlueprint = state.reactivationCampaignBlueprints[id];
        // update if unsaved
        if (campaignBlueprint.saveState === SAVE_STATE.UNSAVED) {
          if (campaignBlueprint.batchUpdateTimeout) clearTimeout(campaignBlueprint.batchUpdateTimeout);
          await dispatch(ACT.UPDATE_REACTIVATION_CAMPAIGN_BLUEPRINT, { id });
        }
        const result = await ReactivationService.publishCampaignBlueprint(id);
        const { updatedAt, publishedAt, publishedCopies } = result.campaignBlueprint;
        commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, {
          id,
          data: {
            updatedAt,
            publishedAt,
            publishedCopies,
          },
        });
        if (result.taskId) {
          eventBus.$emit('pollTask', result.taskId);
        }
        return result;
      } catch (e) {
        console.log(e);
        commit(MUTATE.STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
        return null;
      }
    },

    async [ACT.CREATE_REACTIVATION_EXCLUSION]({ commit, state, dispatch }: IActionContext, { data }: { data: Omit<IExclusion, '_id'> }) {
      const { exclusion, taskId } = await ReactivationService.createExclusion(data);
      if (taskId) {
        eventBus.$emit('pollTask', taskId);
      }
      await dispatch(ACT.SYNC_REACTIVATION);
      return exclusion;
    },

    // persist changes to DB
    async [ACT.UPDATE_REACTIVATION_EXCLUSION]({ commit, state }: IActionContext, { id }: { id: string }) {
      try {
        commit(MUTATE.STORE_REACTIVATION_EXCLUSION_DATA, { id, data: { saveState: SAVE_STATE.SAVING } });

        const updated = await ReactivationService.updateExclusion(id, {
          data: state.reactivationExclusions[id],
        });
        const { newExclusion } = updated;
        // change to saved if it hasn't been marked as unsaved while saving
        if (state.reactivationExclusions[id].saveState === SAVE_STATE.SAVING) {
          commit(MUTATE.STORE_REACTIVATION_EXCLUSION_DATA, {
            id,
            data: { saveState: SAVE_STATE.SAVED, updatedAt: newExclusion.updatedAt },
          });
        }
        return updated;
      } catch (e) {
        console.log(e);
        commit(MUTATE.STORE_REACTIVATION_EXCLUSION_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
        return null;
      }
    },

    async [ACT.CLONE_REACTIVATION_EXCLUSION]({ state, commit, dispatch }: IActionContext, { id }: { id: string }) {
      try {
        const exclusion = await ReactivationService.cloneExclusion(id);
        await dispatch(ACT.SYNC_REACTIVATION);
        return exclusion;
      } catch (error) {
        console.log('Error cloning campaign blueprint', error);
        return null;
      }
    },

    async [ACT.SCHEDULE_SAVE_REACTIVATION_EXCLUSION](
      { commit, dispatch, state, getters }: IActionContext,
      { id, delay }: { id: string; delay?: number }
    ) {
      const batchUpdateDelay = delay !== undefined ? delay : 2000;
      commit(MUTATE.STORE_REACTIVATION_EXCLUSION_DATA, { id, data: { saveState: SAVE_STATE.UNSAVED } });
      if (!getters.hasPermission('reactivation:write')) return;
      const existingBatchUpdateTimeout = state.reactivationExclusions[id].batchUpdateTimeout;
      if (existingBatchUpdateTimeout) {
        clearTimeout(existingBatchUpdateTimeout);
      }
      const batchUpdate = () => {
        dispatch(ACT.UPDATE_REACTIVATION_EXCLUSION, { id });
      };
      const batchUpdateTimeout = setTimeout(batchUpdate, batchUpdateDelay);
      commit(MUTATE.STORE_REACTIVATION_EXCLUSION_DATA, { id, data: { batchUpdateTimeout } });
    },

    async [ACT.UPDATE_REACTIVATION_EXCLUSIONS_ORDER]({ state, getters }: IActionContext, { exclusions }: { exclusions: string[] }) {
      try {
        if (!getters.hasPermission('reactivation:write')) return;
        const orgId = getters.org._id;
        const response = await OrgService.reorderReactivationExclusions(orgId, { exclusions });
        return true;
      } catch (error) {
        console.log('Error reordering reactivation exclusions', error);
        return null;
      }
    },

    async [ACT.PUBLISH_REACTIVATION_EXCLUSION]({ state, dispatch, commit }: IActionContext, { id }: { id: string }) {
      try {
        // freeze operations during publish
        const exclusion = state.reactivationExclusions[id];
        // update if unsaved
        if (exclusion.saveState === SAVE_STATE.UNSAVED) {
          if (exclusion.batchUpdateTimeout) clearTimeout(exclusion.batchUpdateTimeout);
          await dispatch(ACT.UPDATE_REACTIVATION_EXCLUSION, { id });
        }
        const result = await ReactivationService.publishExclusion(id);
        const { updatedAt, publishedAt, publishedCopies } = result.exclusion;
        commit(MUTATE.STORE_REACTIVATION_EXCLUSION_DATA, {
          id,
          data: {
            updatedAt,
            publishedAt,
            publishedCopies,
          },
        });
        if (result.taskId) {
          eventBus.$emit('pollTask', result.taskId);
        }
        return result;
      } catch (e) {
        console.log(e);
        commit(MUTATE.STORE_REACTIVATION_EXCLUSION_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
        return null;
      }
    },

    async [ACT.REACTIVATION_WAITLIST_SIGNUP]({ commit, state }: IActionContext) {
      try {
        if (state.org?._id) {
          await OrgService.update(state.org._id, { reactivationWaitlist: true });
          commit(MUTATE.SET_ORG_DETAILS, { reactivationWaitlist: true });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },

  getters: {
    reactivation(state: IState) {
      return state.org?.reactivation || { enabled: false };
    },
    reactivationAvailable(state: IState, getters: any) {
      return getters.provider === 'stripe' || state?.org?.reactivationBeta;
    },
    reactivationABTestsEnabled() {
      return false;
    },
    reactivationCustomAttributesEnabled() {
      return true;
    },
    reactivationWaitlist(state: IState) {
      return state?.org?.reactivationWaitlist;
    },
    reactivationOnboardState(state: IState, getters: any) {
      return {
        campaignCreated: !!getters.primaryReactivationCampaignBlueprintId,
        emailReady: getters.senderDomainReady,
        billingPageReady: getters.isBillingPageReady,
        activateReady: getters.isReadyToActivate,
        activated: state.org?.reactivation?.enabled,
      };
    },
    currentReactivationCampaignBlueprint(state: IState) {
      if (state.currentReactivationCampaignBlueprintId) {
        return state.reactivationCampaignBlueprints[state.currentReactivationCampaignBlueprintId] || null;
      }
      return null;
    },
    currentReactivationCampaignBlueprintIsOneTime(state: IState, getters: any) {
      if (getters.currentReactivationCampaignBlueprint) {
        return getters.currentReactivationCampaignBlueprint.campaignSchedule === CAMPAIGN_SCHEDULE.ONE_TIME;
      }
      return false;
    },
    currentReactivationCampaignBlueprintLoadState(state: IState, getters: any) {
      if (getters.currentReactivationCampaignBlueprint) {
        return getters.currentReactivationCampaignBlueprint.loadState;
      }
      return undefined;
    },
    currentReactivationCampaignBlueprintSaveState(state: IState, getters: any) {
      if (getters.currentReactivationCampaignBlueprint) {
        return getters.currentReactivationCampaignBlueprint.saveState || SAVE_STATE.SAVED;
      }
      return undefined;
    },
    currentReactivationCampaignEmailBlueprints(state: IState, getters: any): string[] {
      if (getters.currentReactivationCampaignBlueprint) {
        if (getters.currentReactivationCampaignBlueprintIsOneTime) {
          return [...getters.currentReactivationCampaignBlueprint.emails].sort((a: IEmailBlueprint, b: IEmailBlueprint) =>
            ascending(a.sendOnDate ? +new Date(a.sendOnDate) : 0, b.sendOnDate ? +new Date(b.sendOnDate) : 0)
          );
        } else {
          return [...getters.currentReactivationCampaignBlueprint.emails].sort((a: IEmailBlueprint, b: IEmailBlueprint) =>
            ascending(a.sendOnDay, b.sendOnDay)
          );
        }
      }
      return [];
    },
    currentReactivationCampaignEmailBlueprintMap(state: IState, getters: any) {
      if (getters.currentReactivationCampaignEmailBlueprints) {
        const emailBlueprints = getters.currentReactivationCampaignEmailBlueprints as IEmailBlueprint[];
        return emailBlueprints.reduce(
          (acc, emailBlueprint) => {
            acc[emailBlueprint.guid] = emailBlueprint;
            return acc;
          },
          {} as { [key: string]: IEmailBlueprint }
        );
      }
      return {};
    },
    currentReactivationEmailBlueprint(state: IState, getters: any) {
      try {
        return [...getters.currentReactivationCampaignBlueprint.emails].find(
          (emailBlueprint) => emailBlueprint.guid === state.currentReactivationEmailBlueprintId
        );
      } catch (e) {
        console.error('caught error', e);
        return null;
      }
    },
    reactivationBaseEmailBlueprint(state: IState, getters: any) {
      const user = state.user as IUser;
      const currentCampaign = getters.currentReactivationCampaignBlueprint;
      const campaign = generateReactivationSequence({
        from: user.email,
        replyTo: user.email,
        company: state.org?.name || '',
        senderName: user.name || '',
        segmented: false, // does not matter, does not affect emails
        campaignSchedule: currentCampaign.campaignSchedule,
      });
      const { emails } = campaign;
      return emails[0];
    },
    reactivationCampaignBlueprints(state: IState) {
      return Object.values(state.reactivationCampaignBlueprints);
    },
    primaryReactivationCampaignBlueprintId(state: IState) {
      return state.org?.reactivation?.activeCampaign;
    },
    primaryReactivationCampaignBlueprint(state: IState, getters: any) {
      if (getters.primaryReactivationCampaignBlueprintId) {
        return state.reactivationCampaignBlueprints[getters.primaryReactivationCampaignBlueprintId] || null;
      }
      return null;
    },
    mergeFields(): EmailMergeField[] {
      return [
        {
          label: 'First Name',
          id: 'FIRST_NAME',
          fallback: '',
        },
        // {
        //   label: 'Card Brand',
        //   id: 'CARD_BRAND',
        //   fallback: '',
        // },
        // {
        //   label: 'Last 4 of Card',
        //   id: 'LAST_4',
        //   fallback: 'xxxx',
        // },
        {
          label: 'Plan Name',
          id: 'PLAN_NAME',
          fallback: '',
        },
      ];
    },
    reactivationExclusions(state: IState) {
      return Object.values(state.reactivationExclusions);
    },
    hasReactivationSubscription(state: IState, getters: any) {
      if (getters.hasLegacyPricing) {
        return true;
      }
      return hasProductSubscription(state, ProductName.reactivation);
    },
    hasNonTrialReactivationSubscription(state: IState, getters: any) {
      if (getters.hasLegacyPricing) {
        return true;
      }
      return hasNonTrialProductSubscription(state, ProductName.reactivation);
    },
    hasTrialReactivationSubscription(state: IState) {
      return hasTrialSubscription(state, ProductName.reactivation);
    },
    reactivationProduct(state: IState) {
      return getProductByName(state, ProductName.reactivation);
    },
    allRequiredReactivationStepsCompleted(state: IState, getters: any) {
      return [
        getters.paymentProcessorStepCompleted,
        getters.isDunningCustomDomainVerified,
        getters.isDunningSenderDomainVerified,
        getters.primaryReactivationCampaignBlueprintId,
      ].every(Boolean);
    },
    isReactivationProductLive(state: IState, getters: any) {
      if (getters.hasLegacyPricing) {
        return true;
      }
      return getters.reactivationProduct?.isLive || false;
    },
    isReactivationProductAvailable(state: IState, getters: any) {
      return getters.reactivationProduct?.isAvailable || false;
    },
    isReactivationProductTrial(state: IState, getters: any) {
      return getters.reactivationProduct?.isTrial || false;
    },
  },
};

export default reactivationModule;
