import { Route, RouteConfig } from 'vue-router';
import routeConfigs from '@/routes';
import { IState, LAYOUT, NAV_GROUP } from '@/store/types';

// Note: Most helpers in this module assume all routes are defined in the
// @/routes object. These will break if routes are defined elsewhere
// programmatically, e.g. with router.addRoutes().

// Cache & finder for looking up route configs by path
const routeConfigMap: Record<string, RouteConfig | null> = {};
function findRouteConfig(path: string, configs: RouteConfig[] = [], root = ''): RouteConfig | null {
  for (let i = 0; i < configs.length; i++) {
    const config = configs[i];
    const fullPath = [root, config.path].join('/').replace(/\/\/+/g, '/');
    if (fullPath === path) {
      return config;
    }
    const result = findRouteConfig(path, config.children, fullPath);
    if (result) return result;
  }
  return null;
}

// Combine the path from a RouteConfig with a Route's parent path. Used to get the
// full (nonrelative) path of a sibling.
export const siblingPath = (routeConfig: RouteConfig, route: Route): string => {
  const parentRoute = route.matched[route.matched.length - 1].parent;
  if (!parentRoute) return routeConfig.path;
  return [parentRoute.path, routeConfig.path].join('/');
};

// List all sibling route configs for a given route. See note on routeConfigMap
// for caveats
export const siblingRoutes = (route: Route): RouteConfig[] => {
  const parentRoute = route.matched[route.matched.length - 1].parent;
  if (!parentRoute) {
    return routeConfigs;
  }
  let parentConfig: RouteConfig | null = routeConfigMap[parentRoute.path];
  if (parentConfig === undefined) {
    parentConfig = findRouteConfig(parentRoute.path, routeConfigs);
    routeConfigMap[parentRoute.path] = parentConfig;
  }
  return parentConfig?.children || [];
};

// Remove final optional params from the end of a route path
export const stripOptionalParams = (path: string): string => {
  return path.replace(/(\/:[^/]+\?)+$/, '');
};
