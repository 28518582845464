export const zeroDecimalCurrencies = ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF'];

export const threeDecimalCurrencies = ['BHD', 'IQD', 'JOD', 'KWD', 'LYD', 'OMR', 'TND'];

export const getCurrencyDivisor = (currency?: string | null): number => {
  if (!currency) {
    return 100;
  }
  if (zeroDecimalCurrencies.includes(currency.toUpperCase())) {
    return 1;
  }
  if (threeDecimalCurrencies.includes(currency.toUpperCase())) {
    return 1000;
  }
  return 100;
};

export const currencyAmountToCents = (amount: number, currency?: string | null): number => {
  const divisor = getCurrencyDivisor(currency);
  return amount * divisor;
};

export const getCurrencySymbol = (currency: string) =>
  (0).toLocaleString('en-US', { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim();

export const formatIntlCurrency = (value: number, currency?: string | null): string => {
  const divisor = getCurrencyDivisor(currency);
  let amount = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency || 'usd',
    currencyDisplay: 'narrowSymbol',
  }).format(value / divisor);
  // trim off currency trailing decimal zeros
  amount = amount.replace(/\.00$/, '');
  return amount;
};

export const formatIntlPlan = (value: number, currency: string | null, interval: string, intervalCount: number): string => {
  const amount = formatIntlCurrency(value, currency);
  let recurring = '';
  if (intervalCount !== 1) {
    recurring = `/${intervalCount} ${interval}s`;
  } else {
    recurring = `/${interval}`;
  }
  if (intervalCount === 3 && interval === 'month') {
    recurring = '/quarter';
  }
  return `${amount}${recurring}`;
};

export const currencyFormat = (amount: number, currency: string) => {
  let newAmount = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency.toLowerCase() || 'usd',
    currencyDisplay: 'narrowSymbol',
  }).format(amount);
  // trim off currency trailing decimal zeros
  newAmount = newAmount.replace(/\.00$/, '');
  return newAmount;
};

export const currencyRoundedFormat = (amount: number, currency: string) => {
  let newAmount = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency.toLowerCase() || 'usd',
    currencyDisplay: 'narrowSymbol',
  }).format(Math.round(amount));
  // trim off currency trailing decimal zeros
  newAmount = newAmount.replace(/\.00$/, '');
  return newAmount;
};

export const currencyFormatWithDivisor = (amount: number, currency: string) => {
  const divisor = getCurrencyDivisor(currency);
  return currencyFormat(amount / divisor, currency);
};
