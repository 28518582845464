import { DBResultsByType, IAudience, ICampaign, ICampaignBlueprint, IEmail, IExclusion } from '@/store/types';
import Axios from '@/helpers/axios-wrapper';

export default function CampaignService(RESOURCE: string) {
  return {
    /**
     * @param {Object} data the fields to update
     */
    createCampaignBlueprint(data: Omit<ICampaignBlueprint, '_id'>): Promise<ICampaignBlueprint> {
      return Axios.post<ICampaignBlueprint>(`${RESOURCE}/blueprints`, data);
    },

    /**
     * @param {string} id the id of the campaignBlueprint to clone
     */
    cloneCampaignBlueprint(id: string, pushDates: boolean = false): Promise<ICampaignBlueprint> {
      return Axios.post<ICampaignBlueprint>(`${RESOURCE}/blueprints/${id}/clone`, { pushDates });
    },

    // generate Email preview:
    generateEmailPreview(campaignBlueprintId: string, emailBlueprintId: number): Promise<any> {
      return Axios.post<string>(`${RESOURCE}/blueprints/${campaignBlueprintId}/preview`, { emailBlueprintId });
    },

    /**
     * @param {string} campaignBlueprintId the id of the campaignBlueprint to test
     * @param {number} emailBlueprintId id of the specific email within campaignBlueprint.emails
     * @param {string} emailTo the email address to send the test email to
     */
    sendTestEmail(campaignBlueprintId: string, emailBlueprintId: number, emailTo: string): Promise<string> {
      return Axios.post<string>(`${RESOURCE}/blueprints/${campaignBlueprintId}/test`, { emailBlueprintId, emailTo });
    },

    /**
     * @param {string} campaignBlueprintId the id of the campaignBlueprint to test
     * @param {string} smsBlueprintId the id of the specific sms within campaignBlueprint.sms
     * @param {string} phoneNumber the phone number to send the test sms to
     */
    sendTestSms(campaignBlueprintId: string, smsBlueprintGuid: string, phoneNumber: string): Promise<string> {
      return Axios.post<string>(`${RESOURCE}/blueprints/${campaignBlueprintId}/test-sms`, { smsBlueprintGuid, phoneNumber });
    },

    /**
     * @param {String} id is the campaignBlueprint doc's mongo id
     */
    getCampaignBlueprint(id: string): Promise<ICampaignBlueprint> {
      return Axios.get<ICampaignBlueprint>(`${RESOURCE}/blueprints/${id}`);
    },

    /**
     * Get all org campaign blueprints
     */
    async getAllCampaignBlueprints(): Promise<ICampaignBlueprint[]> {
      const response = await Axios.get<ICampaignBlueprint[]>(`${RESOURCE}/blueprints`);
      return response;
    },

    deleteEmailBlueprint({ campaignId, emailId }: { campaignId: string; emailId: string }) {
      return Axios.delete(`${RESOURCE}/email-blueprint/${campaignId}/${emailId}`);
    },

    /**
     * @param {String} id is the campaignBlueprint doc's mongo id
     */
    updateCampaignBlueprint(
      id: string,
      { data, emailsToPropagate, emailsToDelete }: { data: ICampaignBlueprint; emailsToPropagate?: string[]; emailsToDelete?: string[] }
    ): Promise<{ newBlueprint: ICampaignBlueprint; resultsByType: DBResultsByType }> {
      return Axios.put<{ newBlueprint: ICampaignBlueprint; resultsByType: DBResultsByType }>(`${RESOURCE}/blueprints/${id}`, {
        data,
        emailsToPropagate,
        emailsToDelete,
      });
    },

    /**
     * @param {String} id is the campaignBlueprint doc's mongo id
     */
    publishCampaignBlueprint(id: string): Promise<{ campaignBlueprint: ICampaignBlueprint; resultsByType: DBResultsByType; taskId?: string }> {
      return Axios.post<{ campaignBlueprint: ICampaignBlueprint; resultsByType: DBResultsByType; taskId?: string }>(
        `${RESOURCE}/blueprints/${id}/publish`
      );
    },

    /**
     * @param {String} campaignId the doc's mongo id
     */
    getCampaign(campaignId: string): Promise<ICampaign> {
      return Axios.get<ICampaign>(`${RESOURCE}/campaigns/${campaignId}`);
    },

    /**
     * Get all org campaigns
     */
    getAllCampaigns(): Promise<ICampaign[]> {
      return Axios.get<ICampaign[]>(`${RESOURCE}/campaigns`);
    },

    /**
     * Get all org emails
     */
    getAllEmails(): Promise<IEmail[]> {
      return Axios.get<IEmail[]>(`${RESOURCE}/emails`);
    },

    /**
     * @param {String} domainId the doc's mongo id
     * @param {Object} data the fields to update
     */
    updateEmail(emailId: string, data: Partial<IEmail>): Promise<IEmail> {
      return Axios.put<IEmail>(`${RESOURCE}/emails/${emailId}`, data);
    },

    /**
     * Create a (blank) exclusion rule
     */
    createExclusion(data: Omit<IExclusion, '_id'>): Promise<{ exclusion: IAudience; taskId?: string }> {
      return Axios.post<{ exclusion: IExclusion; taskId?: string }>(`${RESOURCE}/exclusions`, data);
    },

    /**
     * Get all org exclusion rules
     */
    async getAllExclusions(): Promise<IExclusion[]> {
      const response = await Axios.get<IExclusion[]>(`${RESOURCE}/exclusions`);
      return response;
    },

    /**
     * @param {string} id the id of the exclusion to clone
     */
    cloneExclusion(id: string): Promise<IExclusion> {
      return Axios.post<IExclusion>(`${RESOURCE}/exclusions/${id}/clone`);
    },

    /**
     * @param {String} id is the exclusion doc's mongo id
     */
    updateExclusion(id: string, { data }: { data: IExclusion }): Promise<{ newExclusion: IExclusion; resultsByType: DBResultsByType }> {
      return Axios.put<{ newExclusion: IExclusion; resultsByType: DBResultsByType }>(`${RESOURCE}/exclusions/${id}`, {
        data,
      });
    },

    /**
     * @param {String} id is the exclusion doc's mongo id
     */
    publishExclusion(id: string): Promise<{ exclusion: IExclusion; resultsByType: DBResultsByType; taskId?: string }> {
      return Axios.post<{ exclusion: IExclusion; resultsByType: DBResultsByType; taskId?: string }>(`${RESOURCE}/exclusions/${id}/publish`);
    },
  };
}
