<template>
  <div id="app" :key="asOrg" :class="[confidential ? 'confidential' : '']">
    <!-- Wait for route to load to avoid layout flash -->
    <AppLoader v-if="$route.name === null" />

    <!-- Render without layout (if configured in route) -->
    <div v-else-if="$route.meta.layout === LAYOUT.NONE">
      <router-view class="min-h-screen" />
    </div>

    <!-- Default to sidebar layout -->
    <sidebar-layout v-else>
      <router-view />
    </sidebar-layout>

    <AdminControls />

    <!-- Upgrade Modal -->
    <el-dialog :visible.sync="promptInvoice" class="p-0" custom-class="container max-w-2xl rounded-lg px-0 py-0 no-padding">
      <InvoicePrompt />
    </el-dialog>

    <TaskMonitor v-if="taskId" :taskId="taskId" :key="taskId" />
  </div>
</template>
<script>
import Userback, { getUserback } from '@userback/widget';
import EventBus from '@/helpers/event-bus';
import * as events from '@/helpers/gtm-events';
import AppLoader from '@/components/UI/Loader/AppLoader.vue';
import TaskMonitor from '@/components/UI/TaskMonitor.vue';
import AdminControls from '@/components/UI/AdminControls.vue';
import SidebarLayout from '@/components/UI/SidebarLayout.vue';
import InvoicePrompt from '@/components/Billing/InvoicePrompt.vue';
import UserService from '@/api/user.service';
import mixpanel from 'mixpanel-browser';
import { LAYOUT } from '@/store/types';
import { TelemetryEvents, trackEvent as trackMixpanelEvent } from '@/analytics';

export default {
  name: 'app',
  components: {
    AppLoader,
    SidebarLayout,
    InvoicePrompt,
    AdminControls,
    TaskMonitor,
  },
  data() {
    return {
      LAYOUT,
      promptInvoice: false,
      intercomSignature: null,
      hideAdminView: false,
      hideViewOnlyNotice: false,
      ckSignature: null,
      taskId: null, // '659189539b38378847d69c96',
    };
  },
  mounted() {
    // Initialize Mixpanel
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      debug: true,
      track_pageview: true,
      persistence: 'cookie',
      ignore_dnt: true,
      cross_site_cookie: true,
      cross_subdomain_cookie: true,
    });

    // Handle Event Tracking
    const vm = this;

    window.hsConversationSettings = { loadImmediately: false };
    EventBus.$on('pollTask', (taskId) => vm.handlePollTask(taskId));
    EventBus.$on('alert', (title, msg) => vm.launchAlert(title, msg));
    EventBus.$on('destroyIntercom', () => vm.destroyIntercom());
    EventBus.$on('updateIntercom', () => vm.updateIntercom());
    EventBus.$on('userbackInitialize', () => vm.userbackInitialize());
    EventBus.$on('userbackOpen', () => vm.userbackOpen());
    EventBus.$on('openChat', (message) => vm.openChat(message));
    EventBus.$on('trackView', (name, path) => vm.trackPageView(name, path));
    EventBus.$on('trackEvent', (event) => vm.trackEvent(event));
    EventBus.$on('trackCkEvent', (data) => vm.trackCkEvent(data));
    EventBus.$on('ckActive', () => {
      this.trackCkEvent({
        event: 'App Viewed',
      });
    });
    if (this.directToInvoice) {
      this.promptInvoice = true;
    }
    window.addEventListener('savvycal.scheduled', (ev) => {
      // The `detail` property contains the following data about the newly-scheduled event:
      // id, startAt, endAt, email, displayName, fields, phoneNumber, timeZone, guests
      const data = ev.detail;

      trackMixpanelEvent(TelemetryEvents.DEMO_BOOKED, { $email: data.email, $name: data.displayName });
      this.trackEvent({ ...events.scheduledDemo, user: data.email });
    });
  },
  computed: {
    confidential() {
      return this.$store.getters.confidential;
    },
    directToInvoice() {
      return this.$store.getters.directToInvoice;
    },
    user() {
      return this.$store.state.user;
    },
    org() {
      return this.$store.state.org;
    },
    asOrg() {
      return this.$store.state.asOrg;
    },
    blueprint() {
      return this.$store.getters.activeBlueprint;
    },
  },
  methods: {
    handlePollTask(taskId) {
      this.taskId = taskId;
    },
    launchAlert(title, msg) {
      this.$notify.error({ title, message: msg });
    },
    trackPageView(name, path) {
      this.$gtm.trackView(name, path);
    },
    trackEvent(event) {
      this.$gtm.trackEvent(event);
    },
    async trackCkEvent(data) {
      const { getters, state } = this.$store;
      const org = this.org;
      const user = this.user;
      if (user.email.includes('@churnkey.co')) {
        return;
      }
      try {
        if (!this.ckSignature) {
          this.ckSignature = await UserService.getChurnkeySignature(this.user._id);
        }
        const signature = this.ckSignature;
        const { event, eventData } = data;
        const userUid = user._id;

        if (window.churnkey?.event) {
          await window.churnkey.event({
            event,
            eventData,
            appId: import.meta.env.VITE_CHURNKEY_APP_ID,
            uid: org._id,
            customerId: org?.stripeCustomerId,
            customerData: {
              name: org?.name,
              provider: getters.provider,
              provider_authorized: org.stripeAuthorized || org.chargebeeAuthorized || org.paddleAuthorized || org.braintreeAuthorized,
              active_blueprint: !!org.activeBlueprint,
              member_count: org.members.length,
            },
            user: {
              uid: userUid,
              data: {
                name: user?.name,
                email: user?.email,
              },
            },
            hash: signature,
          });
        }
      } catch (e) {
        console.log('Error tracking event', e);
      }
    },
    async userbackInitialize() {
      Userback('A-DpXzGGfKekoIbYwwMOnczNdrV')
        .then((ub) => {
          // identify your logged-in users (optional)
          ub.identify(this.user._id, {
            // example data
            name: this.user.name, // example data
            email: this.user.email, // example data
            accountId: this.org._id,
            accountName: this.org.name,
          });
        })
        .catch((e) => {
          console.log('Error initializing userback', e);
        });
    },
    userbackOpen() {
      getUserback().open();
    },
    destroyIntercom() {
      window.Intercom('shutdown');
    },
    openChat(message) {
      this.cssShowIntercom();
      window.Intercom('showNewMessage', message);
    },
    cssHideIntercom() {
      const intercom = document.querySelector('.intercom-launcher');
      if (intercom) {
        intercom.style.display = 'none';
      }
    },
    cssShowIntercom() {
      const intercom = document.querySelector('.intercom-launcher');
      if (intercom) {
        intercom.style.display = 'block';
      }
    },
    async updateIntercom() {
      const { getters, state } = this.$store;
      const { user, org } = state;
      const { orgCustomer } = getters;
      let company;
      if (org) {
        company = {
          id: org._id,
          provider: getters.provider,
          name: org.name,
          stripeCustomerId: org.stripeCustomerId,
          stripeAccountId: org.stripeAccountId,
          stripeAuthorized: org.stripeAuthorized,
          chargebeeAuthorized: org.chargebeeAuthorized,
          paddleAuthorized: org.paddleAuthorized,
          braintreeAuthorized: org.braintreeAuthorized,
          activeBlueprint: !!org.activeBlueprint,
          memberCount: org.members.length,
          hasCompanyAccount: true,
        };
        if (orgCustomer?.customerPrice) {
          company.plan = orgCustomer.customerPrice.id;
          company.price = orgCustomer.customerPrice.amount / 100;
          company.website = user.email.split('@')[1];
          company.companyCustomer = !!orgCustomer.customerSubscription;
        }
      }

      const update = {
        id: user._id,
        // sign email instead of user id
        // user_id: user._id,
        role: 'user',
        name: user.name,
        email: user.email,
        hasAccount: true,
        isCustomer: !!orgCustomer?.customerSubscription,
        stripeCustomerId: org?.stripeCustomerId,
        ...(company && { company }),
        ...(user.salesData || {}),
      };
      if (!user.email.includes('churnkey.co')) {
        if (!this.intercomSignature) {
          this.intercomSignature = await UserService.getIntercomSignature(user._id);
        }
        const signature = this.intercomSignature;
        window.Intercom('boot', {
          app_id: 'twj4mtir',
          user_hash: signature,
          ...update,
        });
        // criteria for hiding intercom
        if (
          orgCustomer?.customerPrice &&
          orgCustomer.customerPrice.amount <= 195 * 100 &&
          // for default tiered pricing
          orgCustomer.customerPrice.amount !== 9500 &&
          !orgCustomer.customerTrial
        ) {
          this.cssHideIntercom();
          window.Intercom('onShow', () => {
            this.cssShowIntercom();
          });
        }
      }
    },
  },
  watch: {
    directToInvoice(value) {
      if (value) {
        this.promptInvoice = true;
      }
    },
    confidential(value) {
      if (value) {
        // set class .confidential on body
        document.body.classList.add('confidential');
      } else {
        document.body.classList.remove('confidential');
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
