<template>
  <content-loader
    width="100"
    height="100"
    viewBox="0 0 100 100"
    :speed="2"
    primaryColor="#F9FAFF"
    secondaryColor="#D8DFF6"
    :primaryOpacity="0.9"
    :secondaryOpacity="0.25"
  >
    <rect x="0" y="0" width="100" height="100" rx="0" />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  components: { ContentLoader },
};
</script>
