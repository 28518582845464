import Vue from 'vue';
import Router from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import EventBus from '@/helpers/event-bus';
import routes from '@/routes';
import * as actions from '@/store/action-types';
import * as mutations from '@/store/mutation-types';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
});

// perform auth check
router.beforeEach(async (to, from, next) => {
  // Router path tracking
  EventBus.$emit('trackView', to.name, to.fullPath);

  // check for audit param and store value
  if (to.query && to.query.q === 'audit') {
    localStorage.setItem('audit', true);
  }

  // check for audit param and store value
  if (to.query && to.query.promo) {
    store.commit(mutations.SET_PROMO, to.query.promo);
  }

  // check for dunning vs cancel flow intent
  if (to.query && to.query.for) {
    store.commit(mutations.SET_PRODUCT_INTENT, to.query.for);
  }

  // check for logout
  if (to.path === '/logout') {
    store.dispatch(actions.LOGOUT);
  }

  // check for email conf token
  if (to.params?.authToken) {
    try {
      await store.dispatch(actions.MARK_EMAIL_CONFIRMED, to.params.authToken);
      if (store.state?.authStatus === 'error') {
        throw new Error('Link Expired');
      }
    } catch (error) {
      if (to.path.includes('magiclink')) {
        EventBus.$emit('alert', 'Link Expired', 'Please retry email reset or contact support.');
      } else {
        EventBus.$emit('alert', 'Link Expired', 'Please contact support.');
      }
    }
  }

  // guard for authenticated routes
  if (to.matched.some((r) => r.meta.requiresAuth === true)) {
    if (store.getters.isLoggedIn) {
      if (store.state && store.state.user === null) {
        try {
          await store.dispatch(actions.SYNC_USER_RECORD);
        } catch (err) {
          console.log('ERROR!', err);
        }
      }
      if (store.state && store.state.org === null) {
        try {
          await store.dispatch(actions.SYNC_ORG);
        } catch (err) {
          console.log('ERROR!', err);
        }
      }
      if (to.matched.some((r) => r.meta.requiresAdmin === true)) {
        if (!store.getters.isAdmin) {
          next('/');
          return;
        }
      }
      if (to.matched.some((r) => r.meta.requiresFeature && store.state.org && !store.state.org[r.meta.requiresFeature])) {
        next('/');
        return;
      }
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;
