import * as ACT from '@/store/action-types';
import { IState, UserRole } from '../types';

export const USER_ROLE: { [key in UserRole]: UserRole } = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  SUPER_MEMBER: 'SUPER_MEMBER',
  SUPER_VIEWER: 'SUPER_VIEWER',
  ORG_OWNER: 'ORG_OWNER',
  ORG_ADMIN: 'ORG_ADMIN',
  ORG_DEVELOPER: 'ORG_DEVELOPER',
  ORG_MEMBER: 'ORG_MEMBER',
  ORG_VIEWER: 'ORG_VIEWER',
};

type UserRoleDetails = {
  value: UserRole;
  label: string;
  description: string;
  isPublic: boolean;
};

export const allRoles = [
  {
    value: USER_ROLE.SUPER_ADMIN,
    label: 'Super Admin',
    description: 'Highest level of access, including all organizational permissions.',
    isPublic: false,
  },
  {
    value: USER_ROLE.SUPER_MEMBER,
    label: 'Super Member',
    description: 'Access to most features and settings, excluding high-level administrative functions.',
    isPublic: false,
  },
  {
    value: USER_ROLE.SUPER_VIEWER,
    label: 'Super Viewer',
    description: 'View-only access across multiple organizations.',
    isPublic: false,
  },
  {
    value: USER_ROLE.ORG_OWNER,
    label: 'Owner',
    description: 'Full access across the organization.',
    isPublic: false,
  },
  {
    value: USER_ROLE.ORG_ADMIN,
    label: 'Admin',
    description: 'Full access across the organization.',
    isPublic: true,
  },
  {
    value: USER_ROLE.ORG_DEVELOPER,
    label: 'Developer',
    description: 'Excludes billing access and user management; includes API keys and settings.',
    isPublic: true,
  },
  {
    value: USER_ROLE.ORG_MEMBER,
    label: 'Member',
    description: 'Excludes billing, user management, and API keys; includes campaign and flow edits.',
    isPublic: true,
  },
  {
    value: USER_ROLE.ORG_VIEWER,
    label: 'Viewer',
    description: 'View-only access, including analytics.',
    isPublic: true,
  },
];

export const availableRoles = allRoles.filter((role) => role.isPublic);

export const roleDetails = allRoles.reduce(
  (acc, role) => {
    acc[role.value] = { ...role };
    return acc;
  },
  {} as Record<UserRole, UserRoleDetails>
);

interface IActionContext {
  commit: any;
  dispatch: any;
  state: IState;
  getters: any;
}

const superAdmin = USER_ROLE.SUPER_ADMIN;
const superMember = USER_ROLE.SUPER_MEMBER;
const superViewer = USER_ROLE.SUPER_VIEWER;
const owner = USER_ROLE.ORG_OWNER;
const admin = USER_ROLE.ORG_ADMIN;
const developer = USER_ROLE.ORG_DEVELOPER;
const member = USER_ROLE.ORG_MEMBER;
const viewer = USER_ROLE.ORG_VIEWER;

enum PERMISSION {
  BILLING = 'billing', // update card, cancel, view invoices
  USERS = 'users', // invite, change role, remove
  PAYMENT_PROVIDER = 'payment_provider', // connect, disconnect
  CANCEL_FLOWS = 'cf', // edit flows and settings
  CUSTOM_DOMAIN = 'custom_domain', // set, remove
  DUNNING_CAMPAIGNS = 'dunning', // edit campaigns and settings
  REACTIVATION_CAMPAIGNS = 'reactivation', // edit campaigns and settings
  API_KEYS = 'api_keys', // create, remove

  AUDIT_LOGS = 'audit_logs', // view
  FEATURE_FLAGS = 'feature_flags',
}

enum OP {
  READ = 'read',
  WRITE = 'write',
}

type PermissionSet = {
  [key: string]: {
    read: boolean;
    write: boolean;
  };
};

export const PERMISSIONS: Record<PERMISSION, Record<'read' | 'write', UserRole[]>> = {
  [PERMISSION.BILLING]: {
    [OP.READ]: [owner, admin, developer, member, viewer, superAdmin, superMember, superViewer],
    [OP.WRITE]: [owner, admin],
  },
  [PERMISSION.USERS]: {
    [OP.READ]: [owner, admin, developer, member, viewer, superAdmin, superMember, superViewer],
    [OP.WRITE]: [owner, admin, superAdmin],
  },
  [PERMISSION.PAYMENT_PROVIDER]: {
    [OP.READ]: [owner, admin, developer, member, viewer, superAdmin, superMember, superViewer],
    [OP.WRITE]: [owner, admin, superAdmin],
  },
  [PERMISSION.API_KEYS]: {
    [OP.READ]: [owner, admin, developer, superAdmin],
    [OP.WRITE]: [owner, admin, developer, superAdmin],
  },
  [PERMISSION.CUSTOM_DOMAIN]: {
    [OP.READ]: [owner, admin, developer, member, viewer, superAdmin, superMember, superViewer],
    [OP.WRITE]: [owner, admin, developer, superAdmin],
  },
  [PERMISSION.CANCEL_FLOWS]: {
    [OP.READ]: [owner, admin, developer, member, viewer, superAdmin, superMember, superViewer],
    [OP.WRITE]: [owner, admin, developer, member, superAdmin, superMember],
  },
  [PERMISSION.DUNNING_CAMPAIGNS]: {
    [OP.READ]: [owner, admin, developer, member, viewer, superAdmin, superMember, superViewer],
    [OP.WRITE]: [owner, admin, developer, member, superAdmin, superMember],
  },
  [PERMISSION.REACTIVATION_CAMPAIGNS]: {
    [OP.READ]: [owner, admin, developer, member, viewer, superAdmin, superMember, superViewer],
    [OP.WRITE]: [owner, admin, developer, member, superAdmin, superMember],
  },

  [PERMISSION.AUDIT_LOGS]: {
    [OP.READ]: [owner, admin, superAdmin],
    [OP.WRITE]: [],
  },
  // super settings
  [PERMISSION.FEATURE_FLAGS]: {
    [OP.READ]: [owner, admin, developer, member, viewer, superAdmin, superMember, superViewer],
    [OP.WRITE]: [superAdmin],
  },
};

const permissionDetails = {
  [PERMISSION.BILLING]: {
    title: 'Billing',
    description: 'View invoices, update card, cancel subscription',
    // font-awesome icon
    icon: 'dollar-sign',
  },
  [PERMISSION.USERS]: {
    title: 'Users',
    description: 'Manage team members and authentication methods',
    icon: 'users',
  },
  [PERMISSION.PAYMENT_PROVIDER]: {
    title: 'Payment Provider',
    description: 'Connect or disconnect your payment provider accounts',
    icon: 'credit-card',
  },
  [PERMISSION.API_KEYS]: {
    title: 'API Keys',
    description: 'Create or remove API keys for your organization',
    icon: 'key',
  },
  [PERMISSION.CUSTOM_DOMAIN]: {
    title: 'Custom Domain',
    description: 'Set or remove custom domains for your organization',
    icon: 'globe',
  },
  [PERMISSION.CANCEL_FLOWS]: {
    title: 'Cancel Flows',
    description: 'Edit flows and settings for managing user cancellations',
    icon: 'heart',
  },
  [PERMISSION.DUNNING_CAMPAIGNS]: {
    title: 'Dunning Campaigns',
    description: 'Edit campaigns and settings for dunning management',
    icon: 'bullhorn',
  },
  [PERMISSION.REACTIVATION_CAMPAIGNS]: {
    title: 'Reactivation Campaigns',
    description: 'Edit campaigns and settings for reactivation efforts',
    icon: 'redo',
  },
  [PERMISSION.AUDIT_LOGS]: {
    title: 'Audit Logs',
    description: 'View audit logs for all activities within your organization',
    icon: 'book',
    hidden: true,
  },
  [PERMISSION.FEATURE_FLAGS]: {
    title: 'Feature Flags',
    description: 'Manage feature flags for your organization',
    icon: 'flag',
    hidden: true,
  },
};

type PermissionDetail = {
  key: string;
  title: string;
  description: string;
  icon: string;
  hidden?: boolean;
  readRoles: string[];
  writeRoles: string[];
};

export const permissionsWithDetails = Object.entries(PERMISSIONS).reduce((acc: PermissionDetail[], [permissionKey, permissionValue]) => {
  const item: PermissionDetail = {
    key: permissionKey,
    ...permissionDetails[permissionKey as PERMISSION],
    readRoles: permissionValue.read || [],
    writeRoles: permissionValue.write || [],
  };
  acc.push(item);
  return acc;
}, [] as PermissionDetail[]);

const PERMISSIONS_BY_ROLE: Record<UserRole, Record<PERMISSION, { read: boolean; write: boolean }>> = [
  superAdmin,
  superMember,
  superViewer,
  owner,
  admin,
  developer,
  member,
  viewer,
].reduce(
  (acc, role) => {
    acc[role] = Object.entries(PERMISSIONS).reduce(
      (innerAcc, [permissionKey, permissionValue]) => {
        const permission: PERMISSION = permissionKey as PERMISSION;
        innerAcc[permission] = {
          read: permissionValue[OP.READ].includes(role),
          write: permissionValue[OP.WRITE].includes(role),
        };
        return innerAcc;
      },
      {} as Record<PERMISSION, { read: boolean; write: boolean }>
    );
    return acc;
  },
  {} as Record<UserRole, Record<PERMISSION, { read: boolean; write: boolean }>>
);

const permissionModule = {
  state: {
    userPermissions: {} as PermissionSet,
  },
  mutations: {
    SET_PERMISSIONS(state: IState, permissions: PermissionSet) {
      state.userPermissions = permissions;
    },
  },
  actions: {
    [ACT.FETCH_PERMISSIONS]({ state, commit }: IActionContext) {
      const { user } = state;
      if (!user) {
        return;
      }
      const permissions = PERMISSIONS_BY_ROLE[user.role];
      // const permissions = PERMISSIONS_BY_ROLE[USER_ROLE.ORG_VIEWER];
      commit('SET_PERMISSIONS', permissions);
    },
  },
  getters: {
    hasPermission: (state: IState) => (sectionAction: string) => {
      const [section, action] = sectionAction.split(':');
      return !!state.userPermissions[section]?.[action];
    },
  },
};

export default permissionModule;
