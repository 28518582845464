import { unparse } from 'papaparse';
import StatsService from '@/api/stats.service';
import { saveAs } from 'file-saver';
import { LOAD_STATE, flattenCampaign } from '../shared';
import * as MUTATE from '../mutation-types';
import * as ACT from '../action-types';
import { IEmailsByDate, IState, LoadState } from '../types';

interface IActionContext {
  commit: any;
  dispatch: any;
  state: IState;
  getters: any;
}

export const generateFetch = ({
  getLoadState,
  setLoadStateMutation,
  fetchValue,
  setValueMutation,
}: {
  getLoadState: (state: IState) => LoadState;
  setLoadStateMutation: string;
  fetchValue: (options: any) => any;
  setValueMutation: string;
  // eslint-disable-next-line arrow-body-style
}) => {
  return async ({ state, commit }: IActionContext, fetchOptions: any) => {
    try {
      if (getLoadState(state) !== LOAD_STATE.LOADING && getLoadState(state) !== LOAD_STATE.LOADED) {
        commit(setLoadStateMutation, LOAD_STATE.LOADING);
        const raw = await fetchValue(fetchOptions);
        commit(setValueMutation, raw);
        commit(setLoadStateMutation, LOAD_STATE.LOADED);
      }
    } catch (e) {
      console.log(e);
      commit(setLoadStateMutation, LOAD_STATE.ERROR_LOADING);
    }
  };
};

const dunningDashboardModule = {
  state: {
    dunningEmailTimeline: [],
    dunningEmailTimelineLoadState: LOAD_STATE.UNLOADED,
  },

  mutations: {
    [MUTATE.SET_EMAIL_TIMELINE](state: IState, stats: IEmailsByDate[]) {
      state.dunningEmailTimeline = stats;
    },
    [MUTATE.SET_EMAIL_TIMELINE_LOAD_STATE](state: IState, loadState: LoadState) {
      state.dunningEmailTimelineLoadState = loadState;
    },
  },

  actions: {
    [ACT.FETCH_EMAIL_TIMELINE]: generateFetch({
      getLoadState: (state: IState) => state.dunningEmailTimelineLoadState,
      setLoadStateMutation: MUTATE.SET_EMAIL_TIMELINE_LOAD_STATE,
      fetchValue: StatsService.dunningEmailTimeline,
      setValueMutation: MUTATE.SET_EMAIL_TIMELINE,
    }),
    async [ACT.DOWNLOAD_DUNNING_CAMPAIGNS]({ getters, state }: IActionContext, options: { filter?: any } = {}) {
      const limit = 5000;
      const count = (await StatsService.filteredDunningCampaigns({
        filter: options.filter || {},
        options: { limit: limit + 10, count: true },
      })) as number;
      if (count > limit) {
        throw new Error(`Download would exceed campaign limit of ${limit}. Please apply more strict filters to download.`);
      }
      const campaigns = await StatsService.filteredDunningCampaigns({ filter: options.filter || {}, options: { limit, skip: 0 } });
      const flattenedSessions = campaigns.map((s) => {
        return flattenCampaign(s);
      });
      const headers = [
        'campaignId',
        'created',
        'customerId',
        'email',
        'active',
        'invoiceDate',
        'recovered',
        'recoverDate',
        'recoveredViaChurnkeyAutoRetry',
        'recoveredViaEmail',
        'recoveredViaSMS',
        'recoveredViaFailedPaymentWall',
        'recoveredViaOther',
        'amountFormatted',
        'amountRecoveredFormatted',
        'amountRecoveredViaChurnkeyAutoRetry',
        'amountRecoveredViaEmail',
        'amountRecoveredViaSMS',
        'amountRecoveredViaFailedPaymentWall',
        'amountRecoveredViaOther',
        'attempts',
        'onTrial',
        'emailsSent',
        'emailsOpened',
        'emailsClicked',
        'emailsBounced',
      ];

      const dataString = unparse(flattenedSessions, {
        columns: headers,
      });
      const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
      const exportDate = getters.dateSelectionExportFormat;
      saveAs(blob, `churnkey-campaigns-${exportDate}.csv`);
      return true;
    },
  },

  getters: {},
};

export default dunningDashboardModule;
