import { IBlueprint } from '@/store/types';
import Axios from 'axios';

const RESOURCE = '/api/blueprints';

export default {
  /**
   * @param {String} blueprintId the doc's mongo id
   */
  get(blueprintId: string): IBlueprint {
    return Axios.get(`${RESOURCE}/${blueprintId}`);
  },

  /**
   * @param {Object} blueprint
   * @param {String} blueprint.name the user defined name
   * @param {String} blueprint.brandImage url of org logo
   * @param {String} blueprint.primaryColor hex string
   * @param {String} blueprint.secondaryColor hex string
   */
  create(blueprint: any): IBlueprint {
    return Axios.post(`${RESOURCE}/`, blueprint);
  },

  /**
   * @param {String} blueprintId the doc's mongo id
   * @param {Object} data the fields to update
   */
  update(blueprintId: string, data: any): IBlueprint {
    return Axios.put(`${RESOURCE}/${blueprintId}`, data);
  },

  /**
   * @param {String} blueprintId the doc's mongo id
   */
  publish(blueprintId: string): { published: IBlueprint; updated: IBlueprint } {
    return Axios.post(`${RESOURCE}/${blueprintId}/publish`);
  },
  /**
   * @param {String} blueprintId the doc's mongo id
   * @param {String} segmentId the doc's mongo id
   */
  publishSegment(blueprintId: string, segmentId: string): { published: IBlueprint; updated: IBlueprint } {
    return Axios.post(`${RESOURCE}/${blueprintId}/publish-segment`, { segmentId });
  },
};
