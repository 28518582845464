import { IWebhook } from '@/store/types';
import Axios from 'axios';

const RESOURCE = '/api/webhooks';

export default {
  /**
   * @param {String} webhookId the doc's mongo id
   */
  get(webhookId: string): IWebhook {
    return Axios.get(`${RESOURCE}/${webhookId}`);
  },

  /**
   * Get all org webhooks
   */
  getAll(): IWebhook[] {
    return Axios.get(`${RESOURCE}/`);
  },

  /**
   * @param {Object} webhook
   */
  create(webhook: any): IWebhook {
    return Axios.post(`${RESOURCE}`, webhook);
  },

  /**
   * @param {String} webhookId the doc's mongo id
   */
  verify(webhookId: string): IWebhook {
    return Axios.post(`${RESOURCE}/${webhookId}/verify`);
  },

  /**
   * @param {String} webhookId the doc's mongo id
   * @param {Object} data the fields to update
   */
  update(webhookId: string, data: any): IWebhook {
    return Axios.put(`${RESOURCE}/${webhookId}`, data);
  },

  /**
   * @param {String} webhookId the doc's mongo id
   */
  delete(webhookId: string): IWebhook {
    return Axios.delete(`${RESOURCE}/${webhookId}`);
  },
};
