import { ITask } from '@/store/types';
import Axios from 'axios';

const RESOURCE = '/api/tasks';

export default {
  /**
   * @param {String} taskId the doc's mongo id
   */
  get(taskId: string): ITask {
    return Axios.get(`${RESOURCE}/${taskId}`);
  },
};
