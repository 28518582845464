// reorder these to do a multi sort, (a) placing all % items first then # (b) within each group, place in relative order total -> last month -> current month, (c) within that place as total (if available), then clicked, then opened, then bounced

export const adminColumnsToExport = [
  '_id',
  'name',
  'Account:AnnualSubscriptionValue:$',
  'Account:BillingInterval',
  'Account:Members:#',
  'Onboard:FirstLiveSession:Date',
  'Onboard:LatestLiveSession:Date',
  'Onboard:LatestTestSession:Date',
  'Account:PaymentProvider',
  'Onboard:PaymentProviderConnected',
  'Onboard:PublishedFlow',
  'Onboard:CustomDomain',
  'Onboard:CustomEmailSender',
  'Onboard:DunningActivated',
  'Onboard:ReactivationEnabled',
  'Account:LastInvoice:$',
  'Account:LastInvoice:Date',
  'Account:LastInvoiceStatus',
  'Account:Created:Date',
  'Account:Updated:Date',
  'Account:AppId',
  'Account:StripeCustomerId',
  'Account:StripeSubscriptionId',
  'Account:StripeAccountId',
  'Feature:SlackConnected',
  'Feature:Flows:#',
  'Feature:ABTests:#',
  'Feature:CustomerHealthEnabled',
  'Feature:PrecisionRetriesEnabled',
  'Feature:TwoFactorAuthEnabled',
  'Feature:TrustBadgesEnabled',
  'Feature:Webhooks',
  'Feature:CustomAttributes',
  'Feature:DataApi',

  // Boosted Revenue Stats
  'CF:BoostedRevenue:$:Total',
  'CF:BoostedRevenue:$:LastMonth',
  'CF:BoostedRevenue:$:CurrentMonth',
  'Recovery:BoostedRevenue:$:Total',
  'Recovery:BoostedRevenue:$:LastMonth',
  'Recovery:BoostedRevenue:$:CurrentMonth',
  'Reactivation:BoostedRevenue:$:CurrentMonth',
  'Reactivation:BoostedRevenue:$:LastMonth',
  'Reactivation:BoostedRevenue:$:Total',

  // cancel flow outcomes
  'CF:SaveRate:%:Total',
  'CF:SaveRate:%:LastMonth',
  'CF:SaveRate:%:CurrentMonth',

  'CF:Count:#:Total',
  'CF:Count:#:LastMonth',
  'CF:Count:#:CurrentMonth',

  'CF:Saves:#:Total',
  'CF:Saves:#:LastMonth',
  'CF:Saves:#:CurrentMonth',

  // recovery campaign outcomes
  'Recovery:Email:$:Total',
  'Recovery:CKRetry:$:Total',
  'Recovery:Wall:$:Total',
  'Recovery:Pending:$:Total',
  'Recovery:RecoveredOutsideCK:$:Total',
  'Recovery:Unrecovered:$:Total',
  'Recovery:Email:$:LastMonth',
  'Recovery:CKRetry:$:LastMonth',
  'Recovery:Wall:$:LastMonth',
  'Recovery:Pending:$:LastMonth',
  'Recovery:RecoveredOutsideCK:$:LastMonth',
  'Recovery:Unrecovered:$:LastMonth',
  'Recovery:Email:$:CurrentMonth',
  'Recovery:CKRetry:$:CurrentMonth',
  'Recovery:Wall:$:CurrentMonth',
  'Recovery:Pending:$:CurrentMonth',
  'Recovery:RecoveredOutsideCK:$:CurrentMonth',
  'Recovery:Unrecovered:$:CurrentMonth',

  'Recovery:Email:%:Total',
  'Recovery:CKRetry:%:Total',
  'Recovery:Wall:%:Total',
  'Recovery:Pending:%:Total',
  'Recovery:RecoveredOutsideCK:%:Total',
  'Recovery:Unrecovered:%:Total',
  'Recovery:Email:%:LastMonth',
  'Recovery:CKRetry:%:LastMonth',
  'Recovery:Wall:%:LastMonth',
  'Recovery:Pending:%:LastMonth',
  'Recovery:RecoveredOutsideCK:%:LastMonth',
  'Recovery:Unrecovered:%:LastMonth',
  'Recovery:Email:%:CurrentMonth',
  'Recovery:CKRetry:%:CurrentMonth',
  'Recovery:Wall:%:CurrentMonth',
  'Recovery:Pending:%:CurrentMonth',
  'Recovery:RecoveredOutsideCK:%:CurrentMonth',
  'Recovery:Unrecovered:%:CurrentMonth',

  // reactivation campaign outcomes

  'Reactivation:Recovered:%:Total',
  'Reactivation:Pending:%:Total',
  'Reactivation:Unrecovered:%:Total',
  'Reactivation:Recovered:%:LastMonth',
  'Reactivation:Pending:%:LastMonth',
  'Reactivation:Unrecovered:%:LastMonth',
  'Reactivation:Recovered:%:CurrentMonth',
  'Reactivation:Pending:%:CurrentMonth',
  'Reactivation:Unrecovered:%:CurrentMonth',

  'Reactivation:Recovered:#:Total',
  'Reactivation:Pending:#:Total',
  'Reactivation:Unrecovered:#:Total',
  'Reactivation:Recovered:#:LastMonth',
  'Reactivation:Pending:#:LastMonth',
  'Reactivation:Unrecovered:#:LastMonth',
  'Reactivation:Recovered:#:CurrentMonth',
  'Reactivation:Pending:#:CurrentMonth',
  'Reactivation:Unrecovered:#:CurrentMonth',

  // recovery email open rates
  'Recovery:Email:Opened:%:Total',
  'Recovery:Email:Clicked:%:Total',
  'Recovery:Email:Bounced:%:Total',
  'Recovery:Email:Opened:%:LastMonth',
  'Recovery:Email:Clicked:%:LastMonth',
  'Recovery:Email:Bounced:%:LastMonth',
  'Recovery:Email:Opened:%:CurrentMonth',
  'Recovery:Email:Clicked:%:CurrentMonth',
  'Recovery:Email:Bounced:%:CurrentMonth',

  'Recovery:Email:#:Total',
  'Recovery:Email:Clicked:#:Total',
  'Recovery:Email:Opened:#:Total',
  'Recovery:Email:Bounced:#:Total',
  'Recovery:Email:#:LastMonth',
  'Recovery:Email:Clicked:#:LastMonth',
  'Recovery:Email:Opened:#:LastMonth',
  'Recovery:Email:Bounced:#:LastMonth',
  'Recovery:Email:#:CurrentMonth',
  'Recovery:Email:Clicked:#:CurrentMonth',
  'Recovery:Email:Opened:#:CurrentMonth',
  'Recovery:Email:Bounced:#:CurrentMonth',

  // reactivation email open rates
  'Reactivation:Email:Clicked:%:Total',
  'Reactivation:Email:Opened:%:Total',
  'Reactivation:Email:Bounced:%:Total',
  'Reactivation:Email:Clicked:%:LastMonth',
  'Reactivation:Email:Opened:%:LastMonth',
  'Reactivation:Email:Bounced:%:LastMonth',
  'Reactivation:Email:Clicked:%:CurrentMonth',
  'Reactivation:Email:Opened:%:CurrentMonth',
  'Reactivation:Email:Bounced:%:CurrentMonth',

  'Reactivation:Email:#:Total',
  'Reactivation:Email:Clicked:#:Total',
  'Reactivation:Email:Opened:#:Total',
  'Reactivation:Email:Bounced:#:Total',
  'Reactivation:Email:#:LastMonth',
  'Reactivation:Email:Clicked:#:LastMonth',
  'Reactivation:Email:Opened:#:LastMonth',
  'Reactivation:Email:Bounced:#:LastMonth',
  'Reactivation:Email:#:CurrentMonth',
  'Reactivation:Email:Clicked:#:CurrentMonth',
  'Reactivation:Email:Opened:#:CurrentMonth',
  'Reactivation:Email:Bounced:#:CurrentMonth',
];
