<template>
  <div class="has-border-radius-16">
    <div class="flex flex-col justify-between px-12 space-y-8 pt-6">
      <div>
        <p class="text-2xl font-bold text-brand-black">You're almost ready to start with Churnkey.</p>
        <p class="mt-4 text-lg font-semibold leading-8 text-gray-700">
          We just need to grab your payment details before you get started and you'll be on your way.
        </p>
      </div>
      <div class="hidden lg:block text-sm">
        <div class="md:grid md:grid-cols-2">
          <a target="_blank" href="https://churnkey.co/feature/cancellation-insights" class="mb-8 flex justify-center md:justify-end group">
            <img src="@/assets/brand/nav-insights.svg" />

            <div class="w-56 ml-4">
              <h4 class="text-brand-black font-semibold group-hover:text-brand-blue">Customer Insights</h4>
              <p class="text-brand-brown group-hover:text-brand-blue">Answers for cancels</p>
            </div>
          </a>

          <a target="_blank" href="https://churnkey.co/feature/customer-segmentation" class="mb-8 flex justify-center md:justify-start group">
            <img src="@/assets/brand/nav-segments.svg" />

            <div class="w-56 ml-4">
              <h4 class="text-brand-black font-semibold group-hover:text-brand-blue">Segments</h4>
              <p class="text-brand-brown group-hover:text-brand-blue">Craft unique experiences</p>
            </div>
          </a>

          <a target="_blank" href="https://churnkey.co/feature/dynamic-offers" class="mb-8 flex justify-center md:justify-end group">
            <img src="@/assets/brand/nav-offers.svg" />

            <div class="w-56 ml-4">
              <h4 class="text-brand-black font-semibold group-hover:text-brand-blue">Dynamic Offers</h4>
              <p class="text-brand-brown group-hover:text-brand-blue">Deals to prevent churn</p>
            </div>
          </a>

          <a target="_blank" href="https://churnkey.co/feature/session-recordings" class="mb-8 flex justify-center md:justify-start group">
            <img src="@/assets/brand/nav-recordings.svg" />

            <div class="w-56 ml-4">
              <h4 class="text-brand-black font-semibold group-hover:text-brand-blue">Session Recordings</h4>
              <p class="text-brand-brown group-hover:text-brand-blue">Watch how customers react</p>
            </div>
          </a>

          <a target="_blank" href="https://churnkey.co/feature/no-code-editor" class="mb-8 flex justify-center md:justify-end group">
            <img src="@/assets/brand/nav-no-code.svg" />

            <div class="w-56 ml-4">
              <h4 class="text-brand-black font-semibold group-hover:text-brand-blue">No-Code Editing</h4>
              <p class="text-brand-brown group-hover:text-brand-blue">Ship changes, fast</p>
            </div>
          </a>

          <a target="_blank" href="https://churnkey.co/feature/subscription-pauses" class="mb-8 flex justify-center md:justify-start group">
            <img src="@/assets/brand/nav-pauses.svg" />

            <div class="w-56 ml-4">
              <h4 class="text-brand-black font-semibold group-hover:text-brand-blue">Subscription Pauses</h4>
              <p class="text-brand-brown group-hover:text-brand-blue">Empower your customers</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer mt-8 border-t-2 bg-brand-sand">
      <div class="max-w-md mx-auto py-5 flex justify-center space-x-4 items-center">
        <el-button @click="openChat" id="publish-payment-cancel">Talk to Us</el-button>
        <a :href="directToInvoice" target="_blank">
          <el-button type="primary">Add Payment Details</el-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/helpers/event-bus';
import Testimonial from '@/components/UI/TestimonialAlt.vue';

export default {
  name: 'InvoicePrompt',
  components: {
    Testimonial,
  },
  computed: {
    directToInvoice() {
      return this.$store.getters.directToInvoice;
    },
  },
  methods: {
    openChat() {
      eventBus.$emit('openChat');
    },
  },
};
</script>
