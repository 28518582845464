import { ascending } from 'd3';
import { generateDunningSequence } from '@/helpers/templates';
import OrgService from '@/api/org.service';
import StatsService from '@/api/stats.service';
import { LOAD_STATE, SAVE_STATE } from '../shared';
import * as MUTATE from '../mutation-types';
import * as ACT from '../action-types';
import {
  EmailMergeField,
  ICampaignBlueprint,
  IEmail,
  IEmailBlueprint,
  IEmailCount,
  IEmailsByDate,
  IEventFilterOptions,
  IState,
  IUser,
  LoadState,
} from '../types';

interface IActionContext {
  commit: any;
  dispatch: any;
  state: IState;
  getters: any;
}

const defaultEventFilterOptions = {
  minDate: new Date(),
  maxDate: new Date(),
  event: [],
  customerId: [],
  customerName: [],
  customerEmail: [],
  userUid: [],
  userName: [],
  userEmail: [],
  uid: [],
};

const generateFetch = ({
  getLoadState,
  setLoadStateMutation,
  fetchValue,
  setValueMutation,
}: {
  getLoadState: (state: IState) => LoadState;
  setLoadStateMutation: string;
  fetchValue: (options: any) => any;
  setValueMutation: string;
  // eslint-disable-next-line arrow-body-style
}) => {
  return async ({ state, commit }: IActionContext, fetchOptions: any) => {
    try {
      if (getLoadState(state) !== LOAD_STATE.LOADING && getLoadState(state) !== LOAD_STATE.LOADED) {
        commit(setLoadStateMutation, LOAD_STATE.LOADING);
        const raw = await fetchValue(fetchOptions);
        commit(setValueMutation, raw);
        commit(setLoadStateMutation, LOAD_STATE.LOADED);
      }
    } catch (e) {
      console.log(e);
      commit(setLoadStateMutation, LOAD_STATE.ERROR_LOADING);
    }
  };
};

const subscriptionAnalyticsModule = {
  state: {
    eventRollup: [],
    eventRollupLoadState: LOAD_STATE.UNLOADED,
    eventFilterOptions: defaultEventFilterOptions,
    eventFilterOptionsLoadState: LOAD_STATE.UNLOADED,
  },

  mutations: {
    [MUTATE.SET_EVENT_ROLLUP](state: IState, stats: IEmailCount[]) {
      state.eventRollup = stats;
    },
    [MUTATE.SET_EVENT_ROLLUP_LOAD_STATE](state: IState, loadState: LoadState) {
      state.eventRollupLoadState = loadState;
    },
    [MUTATE.SET_EVENT_FILTER_OPTIONS](state: IState, options: IEventFilterOptions) {
      state.eventFilterOptions = options;
    },
    [MUTATE.SET_EVENT_FILTER_OPTIONS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.eventFilterOptionsLoadState = loadState;
    },
  },

  actions: {
    [ACT.FETCH_EVENT_ROLLUP]: generateFetch({
      getLoadState: (state: IState) => state.eventRollupLoadState,
      setLoadStateMutation: MUTATE.SET_EVENT_ROLLUP_LOAD_STATE,
      fetchValue: async ({ filter }) => {
        const value = await StatsService.materalizedEventRollup({ filter });
        // sort by date
        value.sort((a, b) => ascending(a.date, b.date));
        return value;
      },
      setValueMutation: MUTATE.SET_EVENT_ROLLUP,
    }),
    [ACT.FETCH_EVENT_FILTER_OPTIONS]: generateFetch({
      getLoadState: (state: IState) => state.eventFilterOptionsLoadState,
      setLoadStateMutation: MUTATE.SET_EVENT_FILTER_OPTIONS_LOAD_STATE,
      fetchValue: async () => {
        const value = await StatsService.eventFilterOptions();
        return value;
      },
      setValueMutation: MUTATE.SET_EVENT_FILTER_OPTIONS,
    }),
  },

  getters: {},
};

export default subscriptionAnalyticsModule;
