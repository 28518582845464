// To simplify request logic, we use request interceptors in main.ts that
// examine the AxiosResponse and throw errors if present, or return the `data`
// property in place of the AxiosResponse object if there is no error. This
// breaks the typing of request methods (Axios.get<T>, Axios.post<T>, etc), so
// the following wrapper coerces returned promises to resolve to the type
// variable (T).

import Axios, { AxiosRequestConfig } from 'axios';

const AxiosWrapper = {
  async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await Axios.get(url, config);
    return response as unknown as T;
  },
  async delete(url: string, config?: AxiosRequestConfig): Promise<void> {
    await Axios.delete(url, config);
  },
  async post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response = await Axios.post(url, data, config);
    return response as unknown as T;
  },
  async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response = await Axios.put(url, data, config);
    return response as unknown as T;
  },
};

export default AxiosWrapper;
