import { computed, Ref } from 'vue';

export function forIn<Obj extends Record<string | number | symbol, unknown>>(
  obj: Obj,
  callback: (value: Obj[keyof Obj], key: keyof Obj) => boolean | undefined | void
) {
  for (const k in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, k)) {
      const res = callback(obj[k], k);
      if (res === false) {
        return;
      }
    }
  }
}

function deepClone(v: any): any {
  switch (typeof v) {
    case 'object':
      if (v === null) {
        return null;
      }
      if (Array.isArray(v)) {
        return v.map(deepClone);
      }
      return Object.fromEntries(Object.entries(v).map(([k, v]) => [k, deepClone(v)]));
    default:
      return v;
  }
}

export function keys<Obj extends Record<string | number | symbol, unknown>>(obj: Obj): (keyof Obj)[] {
  return Object.keys(obj) as (keyof Obj)[];
}

export function pluralize(amount: number, word: string, plural = `${word}s`): string {
  return amount === 1 ? word : plural;
}

export function pluralizeWithCount(amount: number, word: string, plural = `${word}s`): string {
  return `${amount} ${pluralize(amount, word, plural)}`;
}

/**
 * temporary defineModel alternative
 * allows deep reactivity and mutation of nested objects
 */
export function modelWrapper<P extends { value?: Record<string, any> }>(props: P, emit: (event: 'input', value: P['value']) => void) {
  let changes: { path: string[]; value: unknown }[] = [];

  /** Sets a value in Object by path, recursive */
  function setPath(obj: Record<string, any>, path: string[], value: any) {
    if (path.length === 1) {
      // last key, set value
      obj[path[0]] = value;
    } else {
      // get current key from path
      const k = path.shift() as string;
      if (!obj[k]) {
        // if key does not exist or not an object, creates an object
        obj[k] = {};
      }
      // repeat with the rest of the path
      setPath(obj[k], path, value);
    }
  }

  /** emits accumulated changes */
  function update() {
    // deep clone the object
    const obj = deepClone(props.value);
    // go through all changes and apply them
    for (const c of changes) {
      setPath(obj, c.path, c.value);
    }
    // reset changes
    changes = [];
    // emit the new object
    emit('input', obj);
  }

  // timeout saves current timeout id
  let timeout: number | undefined = undefined;

  /** Set recursive project on the object to track changes */
  function setProxy(v: unknown, path: string[] = []): any {
    // if not an object or null, return as is
    if (typeof v !== 'object' || v === null || Array.isArray(v)) return v;
    // deep clone the object
    const obj = { ...v } as Record<string, any>;
    // go through all keys and set proxy on them
    forIn(obj, (value, key) => {
      obj[key] = setProxy(value, [...path, key as string]);
    });
    return new Proxy(obj, {
      set(_, key, value) {
        if (timeout) {
          // if there is update scheduled, clear it
          clearTimeout(timeout);
        }
        // push the change to the changes array
        changes.push({ path: [...path, key as string], value });
        // schedule update
        // required to avoid race condition when multiple changes are made in the same tick
        timeout = window.setTimeout(() => update(), 5);
        return true;
      },
    });
  }

  /** reactive object, has the same behavior as the object returned from defineModel() macro  */
  const value = computed({
    get() {
      return setProxy(props.value);
    },
    set(v) {
      emit('input', v);
    },
  });

  return value as Ref<P['value']>;
}
