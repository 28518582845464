import { ISegment } from '@/store/types';
import Axios from 'axios';

const RESOURCE = '/api/segments';

export default {
  /**
   * @param {String} segmentId the doc's mongo id
   */
  get(segmentId: string): ISegment {
    return Axios.get(`${RESOURCE}/${segmentId}`);
  },

  /**
   * @param {Object} segment
   */
  create(segment: any): ISegment {
    return Axios.post(`${RESOURCE}`, segment);
  },

  /**
   * @param {String} segmentId the doc's mongo id
   * @param {Object} data the fields to update
   */
  update(segmentId: string, data: any): ISegment {
    return Axios.put(`${RESOURCE}/${segmentId}`, data);
  },

  /**
   * @param {String} segmentId the doc's mongo id to clone
   */
  segmentClone(segmentId: string): ISegment {
    return Axios.post(`${RESOURCE}/clone/${segmentId}`);
  },

  /**
   * @param {String} segmentId the doc's mongo id
   * @param {Object} data the fields to update when creating AB Test
   */
  createABTest(segmentId: string, data: any): ISegment {
    return Axios.post(`${RESOURCE}/abtest/${segmentId}`, data);
  },

  /**
   * @param {String} abTestId the doc's mongo id
   * @param {Object} data the fields to update
   */
  updateABTest(abTestId: string, data: any): ISegment {
    return Axios.put(`${RESOURCE}/abtest/${abTestId}`, data);
  },

  /**
   * @param {String} segmentId the doc's mongo id
   */
  publish(segmentId: string): { published: ISegment; updated: ISegment } {
    return Axios.post(`${RESOURCE}/${segmentId}/publish`);
  },
};
