import { TIME_INTERVAL } from '@/store/types';
import { format } from 'd3';
import { timeDay, timeMillisecond, timeMonth, timeWeek, timeYear, utcDay } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import Vue from 'vue';

// returns from the start of the first day to the end of the last
export const getDateEndpoints = ([startDate, endDate]: [Date, Date]): [Date, Date] => {
  const startFloor = new Date(timeDay.floor(startDate));
  const endCeil = new Date(timeMillisecond.offset(timeDay.ceil(endDate), -1));
  return [startFloor, endCeil];
};

const roundToNearestMinutes = (date: Date, interval: number): Date => {
  const minutes = date.getMinutes();
  const remainder = minutes % interval;
  date.setMinutes(minutes + (remainder < interval / 2 ? -remainder : interval - remainder));
  date.setSeconds(0);
  return date;
};

export const getNextAvailableSlot = (interval: number, addHours: number): Date => {
  const sendOnDate = new Date();
  sendOnDate.setHours(sendOnDate.getHours() + addHours);
  const roundedDate = roundToNearestMinutes(new Date(sendOnDate), interval);
  if (roundedDate.getHours() >= 24) {
    roundedDate.setDate(roundedDate.getDate() + 1);
    roundedDate.setHours(roundedDate.getHours() - 24);
  }
  return roundedDate;
};

export const getNextAvailableHalfHourSlot = (): Date => {
  return getNextAvailableSlot(30, 3);
};

export const isSameDate = (date1: Date, date2: Date): boolean => {
  return timeDay(date1).getTime() === timeDay(date2).getTime();
};

// returns from the start of the first day to the end of the last in UTC
// range is based off of offset value
export const getUTCDateEndpointsByOffset = (offSet: number): [Date, Date] => {
  const defaultStartDate = utcDay.floor(utcDay.offset(new Date(), -offSet));
  const defaultEndDate = utcDay.ceil(new Date());
  return [defaultStartDate, defaultEndDate];
};

export const dateFormat = timeFormat('%m/%d/%y');
export const dateTimeLabelFormat = timeFormat('%b %_d');
export const dateMonthYearFormat = timeFormat('%b %_d, %Y');
export const dateFullMonthYearFormat = timeFormat('%B %_d, %Y');
export const dateExportFormat = timeFormat('%Y-%m-%d');
export const dateTimeExportFormat = timeFormat('%Y-%m-%d at %H:%M%p');
export const monthYearLabel = timeFormat('%B %Y');
export const yearLabel = timeFormat('%Y');
export const shortMonthYearLabel = timeFormat('%b %y');
export const dollarFormat = format('$.2f');
export const dateTimeLongFormat = timeFormat('%d-%m-%Y at %H:%M%p');
export const dateTimeShortFormat = timeFormat('%d %m %Y %H:%M%p');

export const dateTimeLocalFormat = (date: Date | string): string => {
  // Convert input date to a Date object if it's a string
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  // Format the date as "2 Feb 01:47am" using toLocaleString with options
  return dateObj
    .toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    })
    .replace(/ /g, ' ');
};

export const getUTCDatefromLocal = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const getLocalDatefromUTC = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

// Format Date Diff into readable strings
export const dateDifferenceInDays = (startDate: Date, endDate: Date): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Convert dates to UTC timestamps
  const startUTC = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
  const endUTC = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

  // Calculate the time difference in milliseconds
  const timeDiff = Math.abs(endUTC - startUTC);

  // Convert milliseconds to days
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  return daysDiff;
};

export const getShortDateRange = (startDate: Date, endDate: Date): string => {
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  });

  const startDateFormatted = dateFormatter.format(startDate);
  const endDateFormatted = dateFormatter.format(endDate);

  return `${startDateFormatted} to ${endDateFormatted}`;
};

export const dateDifferenceFormat = (startDate: Date, endDate: Date): string => {
  const daysDiff = dateDifferenceInDays(startDate, endDate);
  if (daysDiff <= 30) {
    return `${daysDiff} ${daysDiff === 1 ? 'day' : 'days'}`;
  } else if (daysDiff > 30 && daysDiff < 365) {
    const daysToMonths = Math.floor(daysDiff / 30);
    return `${daysToMonths} ${daysToMonths === 1 ? 'month' : 'months'}`;
  } else {
    const daysToYears = Math.floor(daysDiff / 365);
    return `${daysToYears} ${daysToYears === 1 ? 'year' : 'years'}`;
  }
};

export const dateDifferenceDisplayText = (startDate: Date, endDate: Date): string => {
  const daysDiff = dateDifferenceInDays(startDate, endDate);
  const now = new Date();
  const endDiffFromNow = dateDifferenceInDays(now, endDate);
  const startLocalTime = getLocalDatefromUTC(startDate).getTime();

  // Map of specific day differences to corresponding text
  const dateDiffMap: { [key: number]: string } = {
    6: 'Last 7 days',
    29: 'Last 30 days',
    89: 'Last 90 days',
    364: 'Last Year',
  };

  // Check for specific day differences
  if (endDiffFromNow === 0 || endDiffFromNow === 1) {
    if (dateDiffMap[daysDiff]) {
      return dateDiffMap[daysDiff];
    }

    // Check for specific time periods
    if (startLocalTime === timeMonth.floor(timeMonth.offset(now, -6)).getTime()) {
      return 'Last 6 months';
    }
    if (startLocalTime === timeWeek.floor(now).getTime()) {
      return 'This Week';
    }
    if (startLocalTime === timeMonth.floor(now).getTime()) {
      return 'This Month';
    }
    if (startLocalTime === timeYear.floor(now).getTime()) {
      return 'This Year';
    }
  }

  // Adjust for timezone offsets and format the date range
  const offsetEnd = getLocalDatefromUTC(endDate).getTime();
  return `${getShortDateRange(new Date(startLocalTime), new Date(offsetEnd))}`;
};

// Return trend data time interval
export const selectTrendDataTimeInterval = (startDate: Date, endDate: Date): TIME_INTERVAL => {
  const daysDiff = dateDifferenceInDays(startDate, endDate);

  // If date diff from start to end is <34, display data by day
  if (daysDiff <= 34) {
    return TIME_INTERVAL.DAY;
    // If date diff from start to end is >34 and <170
    // Display data by week (max 30 weeks displayed)
  } else if (daysDiff > 34 && daysDiff < 170) {
    return TIME_INTERVAL.WEEK;
    // If date diff from start to end is >210 days
    // Display data by week (min 7 months to be displayed)
  } else {
    return TIME_INTERVAL.MONTH;
  }
};

const msPerMinute = 60 * 1000;
const msPerHour = msPerMinute * 60;
const msPerDay = msPerHour * 24;
const msPerMonth = msPerDay * 30;
const msPerYear = msPerDay * 365;
export function shortTimeAgo(d: Date) {
  const now = new Date();
  const elapsed = now - d;
  if (elapsed < msPerMinute) {
    return `${Math.floor(elapsed / 1000)}s`;
  }
  if (elapsed < msPerHour) {
    return `${Math.floor(elapsed / msPerMinute)}m`;
  }
  if (elapsed < msPerDay) {
    return `${Math.floor(elapsed / msPerHour)}h`;
  }
  if (elapsed < msPerMonth) {
    return `${Math.floor(elapsed / msPerDay)}d`;
  }
  if (elapsed < msPerYear) {
    return `${Math.floor(elapsed / msPerMonth)}m`;
  }
  return `${Math.floor(elapsed / msPerYear)}y`;
}

export function shortTimeAgoExtended(dt: Date) {
  const now = new Date();
  const d = dt instanceof Date && isFinite(dt.getTime()) ? dt : new Date(dt);

  let elapsed = now - d;
  if (elapsed < 0) {
    elapsed = -elapsed;
  }
  let amount;
  if (elapsed < msPerMinute) {
    amount = Math.floor(elapsed / msPerMinute);
    return `${amount} ${amount === 1 ? 'second' : 'seconds'}`;
  }
  if (elapsed < msPerHour) {
    amount = Math.floor(elapsed / msPerMinute);
    return `${amount} ${amount === 1 ? 'minute' : 'minutes'}`;
  }
  if (elapsed < msPerDay) {
    amount = Math.floor(elapsed / msPerHour);
    return `${amount} ${amount === 1 ? 'hour' : 'hours'}`;
  }
  if (elapsed < msPerMonth) {
    amount = Math.floor(elapsed / msPerDay);
    return `${amount} ${amount === 1 ? 'day' : 'days'}`;
  }
  if (elapsed < msPerYear) {
    amount = Math.floor(elapsed / msPerMonth);
    return `${amount} ${amount === 1 ? 'month' : 'months'}`;
  }
  amount = Math.floor(elapsed / msPerYear);
  return `${amount} ${amount === 1 ? 'year' : 'years'}`;
}

export function daysAgo(d: Date) {
  const now = new Date();

  const elapsed = now - d;
  return Math.floor(elapsed / msPerDay);
}

function formatTime(date: Date) {
  // like 8:30am or 11:45pm
  return timeFormat('%-I:%M %p')(date).toLowerCase();
}

function formatDate(date: Date) {
  const day = timeFormat('%e %B')(date);
  const time = formatTime(date);
  return `${day} at ${time}`;
}

function getDayOfWeek(date: Date) {
  return timeFormat('%A')(date);
}

export function quickDateTime(date: Date) {
  const now = new Date();
  const diff = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

  if (diff <= 1) {
    const sameDay = date.toDateString() === now.toDateString();
    const yesterday = date.toDateString() === new Date(now.getTime() - 24 * 60 * 60 * 1000).toDateString();

    if (sameDay) {
      return `Today at ${formatTime(date)}`;
    }
    if (yesterday) {
      return `Yesterday at ${formatTime(date)}`;
    }
  }

  if (diff <= 3) {
    return `${getDayOfWeek(date)} at ${formatTime(date)}`;
  }

  return formatDate(date);
}

Vue.filter('shortTimeAgo', shortTimeAgo);
Vue.filter('shortTimeAgoExtended', shortTimeAgoExtended);
Vue.filter('quickDateTime', quickDateTime);
Vue.filter('dateFormat', dateFormat);
Vue.filter('dateTimeLabelFormat', dateTimeLabelFormat);
Vue.filter('dateTimeLongFormat', dateTimeLongFormat);
Vue.filter('dateTimeLocalFormat', dateTimeLocalFormat);
Vue.filter('dateDifferenceFormat', dateDifferenceFormat);
Vue.filter('dateDifferenceDisplayText', dateDifferenceDisplayText);
Vue.filter('dateDifferenceInDays', dateDifferenceInDays);
Vue.filter('dateMonthYearFormat', dateMonthYearFormat);
Vue.filter('dateFullMonthYearFormat', dateFullMonthYearFormat);
Vue.filter('dateExportFormat', dateExportFormat);
Vue.filter('dateTimeExportFormat', dateTimeExportFormat);
Vue.filter('monthYearLabel', monthYearLabel);
Vue.filter('yearLabel', yearLabel);
Vue.filter('shortMonthYearLabel', shortMonthYearLabel);
Vue.filter('daysAgo', daysAgo);
Vue.filter('dollars', dollarFormat);
