<template>
  <div
    v-if="isAdmin && !hideAdminView"
    class="absolute top-0 z-20 transform -translate-y-4 hover:translate-y-0 transition duration-100 bg-brand-black w-full text-white font-bold px-4 space-x-4 text-sm flex items-center confidential-hidden"
  >
    <button class="text-white text-2xl font-bold" @click="hideAdminView = true">×</button>
    <router-link to="/admin">Admin View </router-link>
    <router-link to="/events">Events</router-link>
    <router-link to="/admin-dashboard">Churn Metrics</router-link>

    <div class="flex-grow flex justify-end">
      <OrgPicker />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import OrgPicker from '@/components/UI/OrgPicker.vue';

export default Vue.extend({
  name: 'AdminControls',
  components: {
    OrgPicker,
  },
  data() {
    return {
      hideAdminView: false,
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
});
</script>
