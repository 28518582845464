<template>
  <div class="px-2 pt-2 pb-3">
    <div class="flex-1 mt-6 w-full space-y-8">
      <div v-for="group in authorizedNavGroups" :key="group.key" class="space-y-1">
        <div v-if="group.name" class="text-brand-sand uppercase text-xs font-bold ml-3">{{ group.name }}</div>
        <router-link
          v-for="route in group.routes"
          :key="route.path"
          :to="route.path"
          :exact="route.path === '/'"
          class="capitalize group w-full px-3 py-2 rounded-md flex flex-col items-start text-sm text-white font-semibold transition-colors"
        >
          <span class="text-center">{{ routeLabel(route) }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarMobileContent',
  computed: {
    ...mapGetters(['authorizedNavGroups', 'routeLabel']),
  },
};
</script>

<style scoped lang="scss">
.router-link-active {
  @apply bg-brand-medium-blue hover:bg-brand-medium-blue;
  @apply text-brand-blue hover:text-brand-blue;
  @apply font-bold;
  @apply hover:bg-opacity-100;
}
</style>
