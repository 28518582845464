import EventBus from '@/helpers/event-bus';
import { IState, LoadState, ActionContext, ActionHandler } from './types';
import { LOAD_STATE } from './shared';
import * as MUT from './mutation-types';
import * as ACT from './action-types';

// Define types for mutation and action values. TODO: the 'as const' casting and
// typeof calls here may be avoided by refactoring mutation-types into an enum
const MUTATIONS = { ...MUT } as const;
type Mutations = typeof MUTATIONS;
type MutationType = Mutations[keyof Mutations];
const ACTIONS = { ...ACT } as const;
type Actions = typeof ACTIONS;
type ActionType = Actions[keyof Actions];

// Used for dependencies and dependents, this defines an action with a
// state-based conditional function to determine wheterh the action should be
// dispatched
type ConditionalAction =
  | ActionType
  | {
      action: ActionType;
      if: (state: IState) => boolean;
    };
async function dispatchConditionalAction(action: ConditionalAction, { state, dispatch }: ActionContext): Promise<void> {
  if (typeof action === 'string') {
    await dispatch(action);
  } else if (action.if(state)) {
    await dispatch(action.action);
  }
}

// ActionPipeline can be used to define actions or groups of actions to be
// dispatched in a sequence. For example, the array:
//   [actions.A, [actions.B1, actions.B2, actions.B3], actions.C]
// would dispatch A and await the result, then dispatch B1, B2, and B3 in
// parallel and await the results, followed by dispatching C.
type ActionPipeline = (ConditionalAction | ConditionalAction[])[];
async function dispatchPipeline(actionPipeline: ActionPipeline, actionContext: ActionContext) {
  await actionPipeline.reduce((promise, step) => {
    return promise.then(async () => {
      // A step in a pipeline can be an array or single item
      let actions: ConditionalAction[];
      if (step instanceof Array) {
        actions = step;
      } else {
        actions = [step];
      }
      await Promise.all(
        actions.map(async (action) => {
          return dispatchConditionalAction(action, actionContext);
        })
      );
    });
  }, Promise.resolve());
}

const enum FetchHook {
  BEFORE_FETCH = 'beforeFetch',
  AFTER_COMMIT = 'afterCommit',
  AFTER_LOADED = 'afterLoaded',
}
type FetchHookMap<T> = Partial<Record<FetchHook, T>>;

interface FetchConfig<T> {
  getLoadState: (state: IState) => LoadState;
  setLoadStateMutation: MutationType;
  fetchValue: (actionContext: ActionContext) => Promise<T | null>;
  setValueMutation: MutationType;
  actions?: FetchHookMap<ActionPipeline>;
  events?: FetchHookMap<string[]>;
}

async function runFetchHooks<T>(hook: FetchHook, { actions, events }: FetchConfig<T>, actionContext: ActionContext) {
  const pipeline = actions?.[hook];
  if (typeof pipeline !== 'undefined') {
    await dispatchPipeline(pipeline, actionContext);
  }
  events?.[hook]?.forEach((e) => {
    EventBus.$emit(e);
  });
}

async function doFetch<T>(config: FetchConfig<T>, actionContext: ActionContext) {
  const { commit } = actionContext;
  commit(config.setLoadStateMutation, LOAD_STATE.LOADING);
  await runFetchHooks(FetchHook.BEFORE_FETCH, config, actionContext);
  const value = await config.fetchValue(actionContext);
  commit(config.setValueMutation, value);
  await runFetchHooks(FetchHook.AFTER_COMMIT, config, actionContext);
  commit(config.setLoadStateMutation, LOAD_STATE.LOADED);
  // Don't await afterLoaded hooks before returning the dispatched method. The
  // load state has been set on the fetched value, so the core operation is done
  runFetchHooks(FetchHook.AFTER_LOADED, config, actionContext);
}

export function generateFetch<T>(config: FetchConfig<T>): ActionHandler {
  let promise: Promise<void> | null = null;
  return async (actionContext: ActionContext) => {
    const { state, commit } = actionContext;
    try {
      // Avoid duplicate fetches if a request is already in process
      if (!promise) {
        promise = doFetch(config, actionContext);
      }
      await promise;
    } catch (e) {
      console.log(e);
      commit(config.setLoadStateMutation, LOAD_STATE.ERROR_LOADING);
    } finally {
      promise = null;
    }
  };
}
